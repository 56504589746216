<template>
  <b-col>
    <!-- TRANSLATED FILES OPTIONS -->
    <b-row v-if="showSubtitlesSelector">
      <!-- EACH TRANSLATED FILE OPTIONS -->
      <b-col
        id="translatedFilesSection"
        style="overflow-y: scroll"
        class="h-100"
      >
        <div
          v-for="(file, index) in vttFilesAvailable"
          :key="index"
        >
          <b-row class="mb-1">
            <b-col class="pt-1">
              {{ file.langName }}
            </b-col>
            <b-col class="pt-1">
              {{ file.mediaAssetName }}
            </b-col>
            <!-- OPTIONS BUTTONS -->
            <!-- TODO dentro de b-col de abajo cols="9" -->
            <b-col class="d-flex justify-content-end">
              <b-button
                v-if="checkPermissions('contents.change_subtitle')"
                variant="link"
                :title="$t('Edit')"
                class="p-0 mr-50"
                @click="editSubtitles(file)"
              >
                <feather-icon
                  class="text-success"
                  icon="EditIcon"
                  size="18"
                />
              </b-button>
              <b-button
                variant="link"
                :title="$t('download')"
                class="p-0 ms-1 mr-50"
                @click="downloadVttFile(file)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="18"
                />
              </b-button>
              <b-button
                v-if="checkPermissions('contents.delete_subtitle')"

                variant="link"
                :title="$t('dataGeneric.delete')"
                class="p-0 ms-1"
                @click="removeVttFile(file)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="text-danger"
                  size="18"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col>
            <b-button
              v-if="checkPermissions('contents.add_subtitle')"
              variant="primary"
              class="mb-1"
              @click="hideNoResults()"
            >
              {{ $t("subtitles.newTranslates") }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <!-- TRANSLATE BUTTON -->
      <!-- TODO superadmin
      <b-col cols="4">
        <b-button
          class="mt-1"
          variant="success"
          @click="showAvailableLanguagesToTranslate()"
        >
          {{ $t("subtitles.newTranslates") }}
        </b-button>
      </b-col> -->
    </b-row>
    <!-- NO RESULTS -->
    <b-row v-if="noSubtitlesGenerated">
      <b-col
        cols="12"
        class="d-flex justify-content-center py-3 h3 text-white"
      >
        {{ $t('subtitles.noSubtitles') }}
      </b-col>
      <b-row
        v-if="checkPermissions('contents.add_subtitle')"
        class="w-100"
      >
        <b-col class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="mb-1"
            @click="hideNoResults()"
          >
            {{ $t("subtitles.newTranslates") }}
          </b-button>
        </b-col>
      </b-row>
    </b-row>
    <!-- SUBTITLE EDITOR -->
    <subtitles-editor
      v-if="showEditSubtitles"
      :vtt-content-array="vttContentArray"
      :original-subtitles="subtitles"
      :file="vttFile"
      :archivo-url-preview="archivoUrlPreview"
      @hideEditor="hideEditor"
    />
    <!-- LANGUAGES TO TRANSLATE -->
    <b-row v-if="showLanguagesToTranslate">
      <b-col
        id="translationSection"
        style="overflow-y: scroll"
        class="h-100"
      >
        <div class="mb-1">
          {{ $t("subtitles.availableLanguages") }}
        </div>
        <!-- AVAILABLE LANGUAGES -->
        <div :class="{ 'two-columns': languages.length > 6 }">
          <div
            v-for="(lang, index) in languages"
            :key="index"
            class="mb-1"
          >
            <b-form-checkbox
              :id="lang.id"
              v-model="selectedLanguages[lang.key]"
              switch
            >
              {{ $t(lang.text) }}
            </b-form-checkbox>
          </div>
        </div>
      </b-col>
      <b-col
        cols="3"
        class="d-flex flex-column"
      >
        <b-button
          variant="success"
          @click="generateTranslatedSubtitles()"
        >
          {{ $t("subtitles.translate") }}
        </b-button>
        <b-button
          class="btn btn-danger mt-1"
          @click="hideLanguagesToTranslate()"
        >
          {{ $t("dataGeneric.goBack") }}
        </b-button>
      </b-col>
    </b-row>
    <!-- GENERATE LANGUAGE -->
    <b-row v-if="showGenerateLanguage">
      <b-col>
        <b-form>
          <b-form-group
            class=""
            :label="$t('url')"
          >
            <validation-provider
              v-slot="validationContext"
              name="urlVideo"
              rules="required|url"
            >
              <b-form-input
                v-model="newLanguageUrl"
                :placeholder="$t('subtitles.url')"
                :state="validationContext.errors.length > 0 ? false : null"
                required
              />
              <small class="text-danger">{{ validationMedia(validationContext) }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            class=""
            :label="$t('subtitles.language')"
          >
            <b-form-select
              v-model="newLanguage"
              :placeholder="$t('subtitles.subtitlesLanguage')"
              :options="languages"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="!isEmpty(newLanguageMediaAssetOptions)"
            class=""
            :label="$t('subtitles.mediaAsset')"
          >
            <b-form-select
              v-model="newLanguageMediaAsset"
              class="mr-1"
              :options="newLanguageMediaAssetOptions"
            />
          </b-form-group>
          <b-button
            class="my-1 mr-1"
            variant="success"
            :disabled="!newLanguage || !newLanguageUrl"
            @click="generateLanguage()"
          >
            {{ $t('subtitles.generateSubtitles') }}
          </b-button>
          <b-button
            class="mx-1"
            variant="danger"
            @click="hideGenerateLanguage()"
          >
            {{ $t('dataGeneric.goBack') }}
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import axios from '@axios'
import { ValidationProvider } from 'vee-validate'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
import {
  checkPermissions, isEmpty, messageError, showToast, utf8ToB64,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import SubtitlesEditor from './SubtitlesEditor.vue'

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    SubtitlesEditor,
    ValidationProvider,
  },
  props: {
    language: {
      type: String,
      default: 'ES',
    },
    mediaAsset: {
      type: Object, // Asegúrate de que el tipo sea el correcto según la estructura de tu objeto mediaAsset
      default: null,
    },
    archivoUrlPreview: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // TODO hacer dinámica la lista de idiomas disponibles
      isSuperuser: false,
      isEmpty,
      checkPermissions,
      languages: [
        { id: '1', text: this.$t('es'), value: 'ES' },
        { id: '2', text: this.$t('caEs'), value: 'CA_ES' },
        { id: '3', text: this.$t('glEs'), value: 'GL_ES' },
        { id: '4', text: this.$t('euEs'), value: 'EU_ES' },
        { id: '5', text: this.$t('en'), value: 'EN' },
        { id: '6', text: this.$t('it'), value: 'IT' },
        { id: '7', text: this.$t('fr'), value: 'FR' },
        { id: '8', text: this.$t('de'), value: 'DE' },
        { id: '9', text: this.$t('pt'), value: 'PT' },
        { id: '10', text: this.$t('ar'), value: 'AR' },
        { id: '11', text: this.$t('zh'), value: 'ZH' },
        { id: '12', text: this.$t('ja'), value: 'JA' },
        { id: '13', text: this.$t('ind'), value: 'ID' },
      ],
      newLanguageUrl: '',
      newLanguage: 'ES',
      newLanguageMediaAsset: this.mediaAsset && this.mediaAsset.id ? this.mediaAsset.id : null,
      newLanguageMediaAssetOptions: {},
      noSubtitlesGenerated: false,
      rowVideoHeight: '',
      selectedLanguages: {},
      showEditSubtitles: false,
      showGenerateLanguage: false,
      showLanguagesToTranslate: false,
      showSubtitlesSelector: false,
      subtitles: null,
      userData: getUserData(),
      vttContentArray: [],
      vttFile: '',
      vttFilesAvailable: [],
    }
  },
  mounted() {
    this.isSuperuser = this.userData.isSuperuser
  },
  created() {
    this.checkSubtitlesExists(true)
    this.fetchMediaAssets()
  },
  methods: {
    checkSubtitlesExists(generate = false) {
      axios
        .post('', {
          query: `
            query ($resource: ID, $mediaAsset: ID) {
              allSubtitles (resource: $resource, mediaAsset: $mediaAsset) {
                edges {
                  node {
                    id
                    language
                    url
                    mediaAsset {
                      id
                      isActive
                      type
                      drmType
                    }
                  }
                }
              }
            }
          `,
          variables: {
            resource: utf8ToB64(`id:${this.$route.params.id}`),
            mediaAsset: this.mediaAsset && this.mediaAsset.id ? utf8ToB64(`id:${this.mediaAsset.id}`) : null,
          },
        })
        .then(response => {
          messageError(response, this)
          if (response.data.data.allSubtitles.edges.length > 0) {
            this.vttFilesAvailable = response.data.data.allSubtitles.edges.map(edge => {
              const languageName = this.languages.find(lang => lang.value === edge.node.language)
              const { mediaAsset } = edge.node
              if (mediaAsset) {
                const { isActive, type, drmType } = edge.node.mediaAsset
                return { ...edge.node, langName: languageName.text, mediaAssetName: this.formatMediaAsset(isActive, type, drmType) }
              }
              return { ...edge.node, langName: languageName.text, mediaAssetName: '' }
            })
            this.showSubtitlesSelector = true
          } else if (generate) {
            // TODO permitir generar subtítulos en un futuro
            this.noSubtitlesGenerated = true
            // this.generateSubtitles()
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    fetchMediaAssets() {
      axios.post('', {
        query: `
          query($resourceId: ID!) {
            allMediaasset(resource: $resourceId) {
              edges {
                node {
                  id
                  isActive
                  type
                  drmType
                }
              }
            }
          }
        `,
        variables: {
          resourceId: utf8ToB64(`id:${this.$route.params.id}`),
        },
      })
        .then(response => {
          this.newLanguageMediaAssetOptions = response.data.data.allMediaasset.edges.map(edge => {
            const { isActive } = edge.node
            const { type } = edge.node
            const { drmType } = edge.node
            const { id } = edge.node
            // Construir la cadena final utilizando la plantilla de cadena
            return { id, text: this.formatMediaAsset(isActive, type, drmType), value: id }
          })
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error('Error', error)
        })
    },
    downloadVttFile(file) {
      const link = document.createElement('a')
      link.href = file.url
      link.target = '_blank'
      link.download = this.getVttFileName(file.url)
      link.click()
    },
    editSubtitles(file) {
      this.getVttContent(file.url)
        .then(vttContent => {
          this.vttFile = file
          this.vttToArray(vttContent)
          this.showSubtitleEditor()
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error('Error', error)
        })
    },
    generateLanguage() {
      axios
        .post('', {
          query: `
            mutation ($resource: ID!, $url: String!, $language: ContentsSubtitleLanguageChoices!, $mediaAsset: ID){
              createSubtitle(input: {
                resource: $resource,
                url: $url,
                language: $language,
                mediaAsset: $mediaAsset,
              }){
                subtitle {
                  id
                  language
                  url
                }
              }
            }
          `,
          variables: {
            resource: this.$route.params.id,
            url: this.newLanguageUrl,
            language: this.newLanguage,
            mediaAsset: this.newLanguageMediaAsset,
          },
        })
        .then(response => {
          messageError(response, this)
          if (response.data.data.createSubtitle.subtitle.url) {
            const languageName = this.languages.find(lang => lang.value === response.data.data.createSubtitle.subtitle.language)
            const selectedMediaAsset = this.newLanguageMediaAssetOptions.find(option => option.value === this.newLanguageMediaAsset)
            const mediaAssetName = selectedMediaAsset ? selectedMediaAsset.text : ''
            this.vttFilesAvailable.push({ ...response.data.data.createSubtitle.subtitle, langName: languageName.text, mediaAssetName })
            this.showSubtitlesSelector = true
            showToast(this.$t('subtitles.generateSuccess'), 1, this)
            this.hideGenerateLanguage()
          } else {
            showToast(this.$t('subtitles.generateError'), 2, this)
            this.$emit('hideModal', 'subtitles')
          }
        })
        .catch(err => {
          showToast(this.$t('subtitles.generateError'), 2, this)
          this.$emit('hideModal', 'subtitles')

          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    generateSubtitles() {
      this.showSubtitlesSelector = true
      /* axios
        .post('', {
          query: `
            mutation ($resource: ID!, $language: ContentsSubtitleLanguageChoices!){
              createSubtitle(input:{
                resource: $resource,
                language: $language,
              }){
                subtitle {
                  id
                  language
                  url
                }
              }
            }
          `,
          variables: {
            resource: this.$route.params.id,
            language: this.language.toUpperCase(),
          },
        })
        .then(response => {
          messageError(response, this)
          if (response.data.data.createSubtitle.subtitle.url) {
            const languageName = this.languages.find(lang => lang.value === response.data.data.createSubtitle.subtitle.language)
            this.vttFilesAvailable.push({ ...response.data.data.createSubtitle.subtitle, langName: languageName.text })
            this.showSubtitlesSelector = true
            showToast(this.$t('subtitles.generateSuccess'), 1, this)
          } else {
            showToast(this.$t('subtitles.generateError'), 2, this)
            this.$emit('hideModal', 'subtitles')
          }
        })
        .catch(err => {
          showToast(this.$t('subtitles.generateError'), 2, this)
          this.$emit('hideModal', 'subtitles')

          // eslint-disable-next-line no-console
          console.log(err)
        }) */
    },
    generateTranslatedSubtitles() {
      const languagesKeys = []
      Object.keys(this.selectedLanguages).forEach(key => languagesKeys.push(key))

      /* TODO revisar funcionamiento
      let query = 'mutation {'
      languagesKeys.forEach((key, i) => {
        query += `
          a_${i + 1}: createSubtitle(input: {
            resource: "${this.$route.params.id}",
            language: "${key}"
          }) {
            subtitle {
              id
              language
              url
            }
          }
        `
      })
      query += '}'

      axios
        .post('', { query })
        .then(response => {
          messageError(response, this)
          showToast(this.$t('subtitles.translationSuccess'), 1, this)
          this.showLanguagesToTranslate = false
          this.checkSubtitlesExists()
        })
        .catch(res => {
          // eslint-disable-next-line
          console.error(res)
          showToast(this.$t('subtitles.translationError'), 2, this)
        }) */
    },
    getVttContent(vttUrl) {
      return new Promise((resolve, reject) => {
        fetch(vttUrl, {
          num: Math.floor(Math.random() * 50),
        })
          .then(response => {
            if (response.ok) {
              return response.text()
            }
            throw new Error('Error')
          })
          .then(content => {
            resolve(content)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getVttFileName(vttUrl) {
      const url = new URL(vttUrl)
      const pathParts = url.pathname.split('/')
      return pathParts[pathParts.length - 1]
    },
    hideEditor() {
      this.$emit('resizeModal', 'lg')
      this.showEditSubtitles = false
      const modalBody = document.querySelector('.modal-body')
      modalBody.style.height = 'fit-content'
      this.showSubtitlesSelector = true
    },
    hideGenerateLanguage() {
      this.showGenerateLanguage = false
      this.newLanguageUrl = ''
      this.newLanguage = ''
      this.newLanguageMediaAsset = this.mediaAsset && this.mediaAsset.id ? this.mediaAsset.id : null
      if (this.vttFilesAvailable.length > 0) {
        this.showSubtitlesSelector = true
      } else {
        this.noSubtitlesGenerated = true
      }
    },
    hideLanguagesToTranslate() {
      this.showLanguagesToTranslate = false
      this.showSubtitlesSelector = true
    },
    hideNoResults() {
      this.showSubtitlesSelector = false
      this.noSubtitlesGenerated = false
      this.showGenerateLanguage = true
    },
    removeVttFile(file) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('borrarSubtítulos'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
                mutation ($id: ID!) {
                  deleteSubtitle (id: $id) {
                    found
                    deletedId
                  }
                }
              `,
              variables: {
                id: file.id,
              },
            })
            .then(response => {
              messageError(response, this)
              if (response.data.data.deleteSubtitle.found) {
                showToast(this.$t('subtitles.deleteSuccess'), 1, this)

                // TODO comprobar que se actualizan los idiomas después de eliminar alguno
                this.checkSubtitlesExists()
              }
            })
            .catch(err => {
              showToast(this.$t('subtitles.deleteError'), 2, this)

              // eslint-disable-next-line no-console
              console.log(err)
            })
        }
      }).catch(() => {

      })
    },
    resetCheckboxes() {
      const checkboxes = document.querySelectorAll('input.custom-control-input')
      checkboxes.forEach(checkbox => { checkbox.checked = false }) // eslint-disable-line no-param-reassign
      this.selectedLanguages = []
    },
    showAvailableLanguagesToTranslate() {
      this.showSubtitlesSelector = false
      this.showLanguagesToTranslate = true

      this.languages = this.languages.filter(lang => !this.vttFilesAvailable.some(generatedLang => generatedLang.language === lang.key))

      this.$nextTick(() => {
        this.resetCheckboxes()
      })
    },
    showSubtitleEditor() {
      this.$emit('resizeModal', 'xl')
      this.showSubtitlesSelector = false
      this.showEditSubtitles = true
    },
    validationMedia(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      if (keyContent.includes('url')) {
        return this.$t('validUrl')
      }
      return ''
    },
    vttToArray(contentVtt) {
      this.subtitles = contentVtt
      const subtitles = contentVtt.split(/\n{2,}/)
      const subtitleArray = []

      let startParsing = false // Variable para indicar si empezar a parsear

      subtitles.forEach(subtitle => {
        // Comprobar si se debe empezar a parsear
        if (subtitle.startsWith('1')) {
          startParsing = true
        }

        // Continuar solo si se debe parsear
        if (startParsing) {
          const subtitleLines = subtitle.split('\n')
          if (subtitleLines.length >= 3) {
            const id = parseInt(subtitleLines[0], 10)
            const [startTime, endTimeUnformatted] = subtitleLines[1].split(' --> ')
            const endTime = endTimeUnformatted?.replace(' align:center', '')
            const content = subtitleLines.slice(2).join('\n')
            const subtitleObject = {
              id,
              startTime,
              endTime,
              content,
            }
            subtitleArray.push(subtitleObject)
          } else if (subtitleLines.length === 2) {
            const id = parseInt(subtitleLines[0], 10)
            const [startTime, endTimeUnformatted] = subtitleLines[1].split(' --> ')
            const endTime = endTimeUnformatted?.replace(' align:center', '')
            const content = ''
            const subtitleObject = {
              id,
              startTime,
              endTime,
              content,
            }
            subtitleArray.push(subtitleObject)
          }
        }
      })

      this.vttContentArray = subtitleArray
    },
    formatMediaAsset(isActive, type, drmType) {
      // Comprobar si drmType es null y asignar "Sin DRM" en su lugar
      const drm = drmType || this.$t('sinDRM')

      // Comprobar si isActive es true o false y asignar "activado" o "desactivado" respectivamente
      const status = isActive ? this.$t('activado') : this.$t('desactivado')

      // Construir la cadena final utilizando la plantilla de cadena
      return `(${status}) / ${type} - ${drm}`
    },
  },
}
</script>

<style scoped lang="scss">

.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

</style>
