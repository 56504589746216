export const getTypeFile = file => {
  const mimeToExtension = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'audio/mp3': 'mp3',
    'audio/mpeg': 'mp3',
    'video/mp4': 'mp4',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'text/plain': 'txt',
    'text/vtt': 'vtt',
    'application/x-subrip': 'srt',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  }

  return mimeToExtension[file] || 'unknown'
}

export const handleRawExtensionType = file => {
  // Diccionario de las extensiones permitidas y sus respectivos mimeTypes
  const rawExtensionToMimeType = {
    vtt: 'text/vtt',
    srt: 'application/x-subrip',
  }

  const rawExtension = file.name.split('.').pop().toLowerCase()

  // Setear al mimeType a traves del diccionario
  if (!file.type || file.type === '') {
    const correctedMimeType = rawExtensionToMimeType[rawExtension]

    Object.defineProperty(file, 'type', {
      value: correctedMimeType,
      writable: false,
      configurable: true,
    })
  }
  return file
}
