<template>
  <b-link
    :disabled="loading || disabled"
    class="cursor-link text-danger"
    @click="confirmReorder"
  >
    <feather-icon
      icon="AlertTriangleIcon"
      class="mr-50"
    />
    <span v-if="loading">{{ $t('platformSelector.fixingOrder') }}</span>
    <span v-else>{{ $t('platformSelector.fixOrder') }}</span>
  </b-link>
</template>

<script>
import axios from '@axios'
import { showToast } from '@/store/functions'

import {
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      BATCH_SIZE: 300,
    }
  },
  methods: {
    async fetchAllPlatforms(offset = 0, allPlatforms = []) {
      try {
        const query = `
          query {
              allClients(
                  orderBy: "order",
                  first: ${this.BATCH_SIZE},
                  offset: ${offset}
              ) {
                  pageInfo {
                      hasNextPage
                  }
                  edges {
                      node {
                          id
                          order
                      }
                  }
              }
          }`

        const data = await axios.post('', { query }).then(res => res.data.data.allClients)

        const platforms = [...allPlatforms, ...data.edges]

        if (data.pageInfo.hasNextPage) {
          return this.fetchAllPlatforms(offset + this.BATCH_SIZE, platforms)
        }
        return platforms
      } catch (error) {
        console.error('Error fetching platforms:', error)
        return []
      }
    },

    async updatePlatformOrder(id, newOrder) {
      // TODO: Hacer la ordenación desde el backend, haciendo una única llamada a una mutación específica
      const mutation = `
        mutation {
            updateClient(id: "${id}", input: { order: ${newOrder} }) {
                client {
                    id
                    name
                    order
                }
            }
        }`

      try {
        await axios.post('', { query: mutation })
      } catch (error) {
        console.error(error)
        showToast(this.$t('error'), 0, this)
      }
    },

    confirmReorder() {
      this.$swal({
        title: this.$t('code.title'),
        html: this.$t('platformSelector.beforeReorder'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('platformSelector.confirmReorder'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.reorderPlatforms()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async reorderPlatforms() {
      this.loading = true
      this.$emit('reordering')
      const platforms = await this.fetchAllPlatforms()

      if (platforms.length === 0) {
        this.loading = false
        return
      }

      platforms.sort((a, b) => a.node.order - b.node.order)

      // Prepare all update requests in parallel
      const updatePromises = platforms.map((item, index) => {
        const platform = item.node
        const newOrder = index + 1 // Start from 1

        if (platform.order !== newOrder) {
          return this.updatePlatformOrder(platform.id, newOrder)
        }
        return null
      }).filter(Boolean) // Remove null values (platforms that don't need updating)

      // Execute all updates concurrently
      await Promise.all(updatePromises)
      showToast(this.$t('clientOrderSuccess'), 1, this)

      this.$emit('reordered')
      this.loading = false
    },

  },
}
</script>
