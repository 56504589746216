<template>
  <section id="media">
    <b-row class="mb-2 mt-1">
      <b-col
        style="text-align-last: start;"
        class="ml-1"
      >
        <h3>{{ $t("media.mediaSubtitle") }}</h3>
      </b-col>
      <b-col
        style="text-align-last: end;"
        class="mr-1"
      >
        <b-button
          :disabled="isEmpty(fileToUser)"
          variant="success"
          @click="uploadFiles"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <b-row class="d-flex flex-wrap">
        <b-col>
          <b-table
            :striped="true"
            sticky-header="500px"
            :no-border-collapse="true"
            responsive="sm"
            :selectable="true"
            :empty-text="$t('emptyText')"
            :items="searchFiles"
            :fields="columns"
            @row-selected="onRowSelected"
          >
            <template #cell(name)="data">
              <b-row style="align-items: center;">
                <b-col
                  v-if="data.item.node.type == 'IMG'"
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <b-img
                    class="embed-responsive-item cursor-pointer"
                    :src="data.item.node.fileUrl"
                    style="object-fit: contain;"
                    loading="lazy"
                    alt="image"
                    @click.stop="event => showModal(data.item.node, event)"
                    @error="errorImg"
                  />
                </b-col>
                <b-col
                  v-else-if="data.item.node.type == 'SUB'"
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <b-img
                    class="embed-responsive-item cursor-pointer"
                    :src="require('@/assets/images/icons/subtitle.svg')"
                    loading="lazy"
                    alt="image"
                    @click.stop="event => showModal(data.item.node, event)"
                  />
                </b-col>
                <b-col
                  v-else-if="data.item.node.type == 'VID'"
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <video
                    id="demo"
                    class="video-js vjs-default-skin vjs-big-play-centered tamanio cursor-pointer"
                    data-setup="{&quot;liveui&quot;: true}"
                    preload="metadata"
                    :src="data.item.node.fileUrl"
                    @click.stop="event => showModal(data.item.node, event)"
                  />
                </b-col>
                <b-col
                  v-else-if="data.item.node.type == 'AUD'"
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <b-img
                    class="embed-responsive-item cursor-pointer"
                    :src="require('@/assets/images/icons/audio.svg')"
                    loading="lazy"
                    alt="image"
                    style="object-fit: contain"
                    @click.stop="event => showModal(data.item.node, event)"
                  />
                </b-col>
                <b-col
                  v-else
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <b-img
                    class="embed-responsive-item cursor-pointer"
                    :src="require('@/assets/images/icons/document.png')"
                    loading="lazy"
                    alt="image"
                    @click.stop="event => showModal(data.item.node, event)"
                  />
                </b-col>

                <b-col id="acortarPadre">
                  <h5 id="acortar">
                    {{ data.item.node.name }}
                  </h5>
                </b-col>
                <b-form-file
                  id="changeFile"
                  ref="changeFile"
                  class="d-none"
                  :accept="acceptedFileTypes"
                  @change="handleFileChange"
                />
                <!-- Some browsers do not detect mimeTypes for Subtitle files formats vtt and srt-->
              </b-row>
            </template>
            <template #cell(type)="data">
              <div class="text-nowrap">
                {{ getNodeTypeLabel(data.item.node.type) }}
              </div>
            </template>
            <template
              v-if="multiplatformUserManager"
              #cell(platform)="data"
            >
              <div class="text-nowrap">
                {{ getMediaPlatform(data.item.node.id) }}
              </div>
            </template>
            <template #cell(size)="data">
              <div class="text-nowrap">
                <span :id="data.item.node.id">
                  ------
                </span>
              </div>
            </template>
            <template #cell(time)="data">
              <div class="text-nowrap">
                {{
                  data.item.node.type === "IMG" || data.item.node.type === "SUB" || data.item.node.type ==
                    "DOC"
                    ? "------------"
                    : formatTime(data.item.node.mediaDuration)
                }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-nowrap">
                {{ formatDate(data.item.node.createdAt) }}
              </div>
            </template>
            <template #cell(actions)="data">
              <div
                class="d-flex justify-content-center"
                @click.stop="event => showModal(data.item.node, event)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="text-success mr-2"
                  size="20"
                />
                <!-- {{ $t('see') }} -->
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex ">
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            @input="fetchData()"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      ref="view-file-modal"
      size="lg"
      hide-footer
      :title="$t('media.viewFile')"
    >
      <div class="d-block text-center">
        <view-file-modal
          :datos="datosObject"
          :update-file="boolFile"
          :upload-data="uploadData"
          :progress="uploadData.progress"
          @update="saveNewFile"
        />
      </div>
    </b-modal>
  </section>
</template>

<script>
import * as constants from '@core/utils/constants'

import {
  BCard,
  BCol,
  BRow,
  BButton,
  BTable,
  VBTooltip,
  BPagination,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError,
  utf8ToB64,
  showToast,
  b64ToUtf8,
  isUserInGroup,
  isEmpty,
} from '@/store/functions'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import { getUserData } from '@/auth/utils'
import ViewFileModal from './ViewFileModal.vue'
import { getTypeFile, handleRawExtensionType } from '../contents/media/utils'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BImg,
    ViewFileModal,
    BCard,
    BCol,
    BRow,
    BTable,
    BPagination,
    BButton,
    BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    multiplatformUserManager: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      platformId: null,
      isEmpty,
      tipo: 'VID',
      overlay: false,
      idEditFile: null,
      boolFile: false,
      destRes: null,
      userData: getUserData(),
      idCont: null,
      idfile: null,
      initialRes: false,
      fileToUser: [],
      contentResourceSelected: null,
      type: 0,
      interactivitieResource: [],
      contentResource: [],
      search: '',
      files: null,
      searchFiles: null,
      inputFolderName: '',
      inputFolderDescription: '',
      uploadFilesList: [],
      datosObject: null,
      fileInput: null,
      dataFileInput: [],
      subiendo: false,
      contentResourcesListForUpdate: [],
      resInfo: null,
      sponsor: false,
      busy: false,
      processing: false,
      counter: 1,
      interval: '',
      dataOverlay: '',
      onHidden: false,
      totalContadorDescarga: 0,
      recibiendoDescarga: 0,
      aud: 0,
      vid: 0,
      img: 0,
      count_subtitles: 0,
      rows: 0,
      perPage: 20,
      currentPage: 1,
      uploadData: {
        key: null,
        file: null,
        duration: 0,
        policy: {},
        progress: null,
      },
      typeFile: null,
      columns: [
        { key: 'name', label: this.$t('nameFile') },
        { key: 'type', label: this.$t('code.type') },
        { key: 'time', label: this.$t('contents.Duration') },
        { key: 'fecha', label: this.$t('dateUp') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      isNewFile: true,
      valuesData: [],
      acceptedFileTypes: [
        'image/jpeg',
        'image/png',
        'audio/mp3',
        'audio/mpeg',
        'video/mp4',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'text/vtt',
        '.vtt',
        'application/x-subrip',
        '.srt',
      ].join(','),
      documentTypes: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
      ],
      subtitleTypes: [
        'text/vtt',
        'application/x-subrip',
      ],
    }
  },
  watch: {
    changeFile(newValue) {
      const getType = key => {
        switch (key) {
          case 'VID':
            return this.$t('fileTypes.video')

          case 'AUD':
            return this.$t('fileTypes.audio')

          case 'IMG':
            return this.$t('fileTypes.image')

          case 'SUB':
            return this.$t('fileTypes.subtitle')

          default:
            return null
        }
      }
      if (newValue) {
        const key = Math.random()
          .toString(36)
          .substring(7)
        this.uploadData = {
          key,
          file: newValue,
          duration: 0,
          policy: {},
          progress: null,
        }
        URL.revokeObjectURL(newValue)
        let datos = { fileUrl: URL.createObjectURL(newValue) }
        // Diccionario de mime types permitidos y sus respectivos tipos
        const mimeTypeToType = {
          'image/': 'IMG',
          'video/mp4': 'VID',
          'audio/mp3': 'AUD',
          'audio/mpeg': 'AUD',
          'text/vtt': 'SUB',
          'application/x-subrip': 'SUB',
          'audio/aac': 'AUD',
        }

        // Función para determinar el tipo
        const determineType = valueType => {
          const fileToChange = handleRawExtensionType(valueType)
          const mimeType = Object.keys(mimeTypeToType).find(mimeTypeValue => fileToChange.type.startsWith(mimeTypeValue))
          return mimeType ? mimeTypeToType[mimeType] : null
        }

        datos = { ...datos, name: newValue.name }

        const type = determineType(newValue) || null
        if (this.typeFile === type) {
          this.showModal(datos)
          this.boolFile = true
        } else {
          this.$swal({
            title: 'ERROR',
            icon: 'error',
            text: getType(type)
              ? this.$t('notOverwrite', { typeFile: getType(this.typeFile), type: getType(type) })
              : this.$t('reg', { typeFile: getType(this.typeFile) }),
            showCancelButton: false,
            confirmButtonText: this.$t('code.accept'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {

          }).catch(() => {

          })
        }
      }
    },
    fileInput() {
      this.subiendo = false
      this.dataFileInput = []

      this.fileInput.forEach(element => {
        const existingValues = this?.valuesData.find(item => item.file.name === element.name)
        this.processFile(element, this.isNewFile, existingValues)
      })
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.sponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    if (this.multiplatformUserManager) this.columns.splice(2, 0, { key: 'platform', label: this.$t('plataforma') })
    this.loadData()
  },
  methods: {
    getFilesByPlatform(data) {
      this.$refs['files-by-platform'].show()
      this.$refs['files-platform'].hide()
      this.platformId = data.id
    },
    getNodeTypeLabel(type) {
      const typeLabels = {
        VID: 'Vídeo',
        AUD: 'Audio',
        IMG: 'Imagen',
        SUB: this.$t('fileTypes.subtitle'),
        DOC: 'Documento',
      }
      return typeLabels[type] || 'Desconocido'
    },
    saveNewFile(duration, newName) {
      const idClient = b64ToUtf8(this.userData.profile.client.id).split(':')[1]

      if (this.userData.profile.client.bucketType === 'AS3') {
        axios
          .post('', {
            variables: {
              size: this.uploadData.file.size.toString(),
              name: getTypeFile(this.uploadData.file.type),
              type: this.uploadData.file.type,
              idClient,
            },
            query: `
                mutation ($name: String!,$type: String!,$idClient: ID!,$size: String!) {
                  createS3Policy(name:$name, type: $type, size: $size, idClient: $idClient){
                    result
                    ok
                  }
                }
              `,
          }).then(response => {
            messageError(response, this)
            const self = this
            const policy = JSON.parse(
              response.data.data.createS3Policy.result.replaceAll("'", '"'),
            )
            this.uploadData.policy = policy
            const config = {
              baseURL: policy.url,
              onUploadProgress(progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                )
                self.uploadData.progress = percentCompleted
              },
              transformRequest: (data, axiosHeaders) => {
                const headers = axiosHeaders
                delete headers.Authorization
                return data
              },
              timeout: 1000 * 60 * 30,
              withCredentials: false,
            }
            const data = new FormData()
            Object.entries(this.uploadData.policy.fields).forEach(([key2, value]) => {
              data.append(key2, value)
            })
            data.append('file', this.uploadData.file)
            axios
              .post('', data, config).then(s3response => {
                if (s3response.status === 204) {
                  const config2 = {
                    timeout: 1000 * 60 * 30,
                  }
                  const data2 = new FormData()
                  const query = `
                  mutation{
                    updateFile(id:"${this.idEditFile}",input:{
                      name: "${newName || this.uploadData.file.name}",
                      media: "${this.uploadData.policy.fields.key}",
                      mediaDuration: ${duration},
                    }){
                      file{
                            id
                            name                         
                          }
                        }
                      }
                `
                  data2.append('query', query)
                  axios
                    .post('', data2, config2)
                    .then(createResponse => {
                      messageError(createResponse, this)

                      if (createResponse.status === 200) {
                        showToast(this.$t('success'), 1, this)
                        this.hideModal()
                        if (this.multiplatformUserManager) this.addFileToMultiplatformUser()
                        else this.loadData()
                      }
                    })
                    .catch(err => {
                      console.log(err)
                      showToast(this.$t('error'), 2, this)
                    })
                }
              })
              .catch(err => {
                console.log(err)
                showToast(this.$t('error'), 2, this)
              })
          }).catch(err => {
            console.log(err)
            showToast(this.$t('error'), 2, this)
          })
      } else {
        const self = this
        const config = {
          onUploadProgress() {
            self.uploadData.progress = 75
          },
          timeout: 1000 * 60 * 30,
        }
        const data = new FormData()
        const query = `
                  mutation{
                    updateFile(id:"${this.idEditFile}",input:{
                      name: "${newName || this.uploadData.file.name}",
                      media: "${this.uploadData.policy.fields.key}",
                      mediaDuration: "${duration}",
                    }){
                      file{
                            id
                            name                         
                          }
                        }
                      }
                `

        data.append('query', query)
        data.append('file', this.uploadData.file)
        axios
          .post('', data, config)
          .then(createResponse => {
            if (createResponse.status === 200) {
              self.uploadData.progress = 100
              showToast(this.$t('success'), 1, this)
              this.hideModal()
              if (this.multiplatformUserManager) this.addFileToMultiplatformUser()
              else this.loadData()
            }
          })
          .catch(err => {
            console.log(err)
            showToast(this.$t('error'), 2, this)
          })
      }
    },
    editFileResource(node) {
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        icon: 'warning',
        text: this.$t('code.ifCont'),
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.idEditFile = node.id
          this.typeFile = node.type
          document.getElementById('changeFile').click()
        }
      }).catch(() => {

      })
    },
    inputSearch(event) {
      const keycode = event.keyCode || event.which

      if (keycode === 13) this.searching()
    },
    loadData() {
      this.searching()
      if (!this.multiplatformUserManager) this.totalCount()
    },
    searching() {
      this.currentPage = 1
      if (!this.multiplatformUserManager) this.fetchData()
      else this.fetchMultiPlatformUserData()
    },
    overOn(node) {
      this.processing = false
      this.busy = true
      this.dataOverlay = node
      this.$swal({
        title: this.$t('code.title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.onOK()
        } else { this.onCancel() }
      }).catch(() => {

      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.totalContadorDescarga = 0
      this.recibiendoDescarga = 0
      this.downloadFile(this.dataOverlay)
      this.processing = true
    },
    downloadFile(file) {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', file.fileUrl, true)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL
        const videoUrl = urlCreator.createObjectURL(this.response)
        const tag = document.createElement('a')
        tag.href = videoUrl
        tag.download = file.name
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
      }
      xhr.onloadend = () => {
        this.busy = false
      }
      xhr.onprogress = event => {
        // se dispara periódicamente
        // event.loaded - cuántos bytes se han descargado
        // event.lengthComputable = devuelve true si el servidor envía la cabecera Content-Length (longitud del contenido)
        // event.total - número total de bytes (si `lengthComputable` es `true`)
        // console.log(`Recibido ${event.loaded} of ${event.total}`);
        this.totalContadorDescarga = event.total
        this.recibiendoDescarga = event.loaded
      }
      xhr.onerror = err => {
        console.log(err)
      }
      xhr.send()
    },
    editResources(type, data) {
      this.type = type
      this.idCont = data.id
      if (type === 0) {
        this.contentResourcesListForUpdate = data.contentResources.edges.length
          ? data.contentResources.edges.map(item => item.node.resource.id)
          : []
      } else {
        try {
          this.resInfo = data.resourceStart.id === this.contentResourceSelected
            ? data.resourceStart
            : data.resourceEnd

          this.destRes = data.resourceStart.id === this.contentResourceSelected
            ? 'START'
            : 'END'

          this.tipo = this.resInfo.type
        } catch (error) {
          console.log(error)
        }
      }
      this.$refs['resource-modal'].show()
    },
    selectResource(data) {
      this.$refs['resource-modal'].hide()
      const [resource] = data
      this.contentResourceSelected = resource

      if (this.type === 0) { this.updateResourceInitial() } else this.updateResourceInt()
    },
    updateResourceInt() {
      const id = this.contentResourceSelected

      const query = `
           mutation{
            updateInteractivities(id:"${this.idCont}",input:{${this.destRes !== 'START'
  ? `resourceEnd:"${id}"`
  : `resourceStart:"${id}"`
}}){
              interactivity{
                id
                name
              }
            }
          }
        `
      axios
        .post('', {
          query,
        })
        .then(result => {
          messageError(result, this)

          this.deleteResource()
        })
        .catch(
          () => { },
        )
    },
    updateResourceInitial() {
      const id = this.idCont

      let query = `
         {
          allContents(id:"${id}") {
            edges {
              node {
                id
                name
                resources {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                contentResources {
                  edges {
                    node {
                      id
                      isInitial
                      resource {
                        id
                        name
                      }
                    }
                  }
                }              
              }
            }
          }
          }
        `

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          const contentResources = res.data.data.allContents.edges[0].node.contentResources.edges

          if (contentResources.length === 0) {
            query = 'mutation{'
            query += `inicial: createContentsResource(input:{content:"${id}",resource:"${this.contentResourceSelected}",isInitial:true}) {
                   contentResource2{
                  id
                isInitial
                  content
                  resource{
                    id
                    name
                    file{
                      id
                      mediaDuration
                    }
                  }
                }
                }
                `

            query += '}'

            axios
              .post('', {
                query,
              })
              .then(result => {
                messageError(result, this)

                const duration = result.data.data?.inicial?.contentResource2?.resource?.file
                  .mediaDuration
                axios
                  .post('', {
                    query: `mutation{
                          updateContents(id:"${id}",input:{duration:${duration || 0}}){
                            content{
                              id
                              name
                              duration
                            }
                          }
                        }`,
                  })
                  .then(r => {
                    messageError(r, this)

                    this.deleteResource()
                  })
                  .catch(() => { })
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            let query2 = 'mutation{'
            contentResources.forEach((element, i) => {
              query2 += `a${i}:deleteContentsResource(id:"${element.node.id}"){
                found
                  deletedId
                }`
            })
            query2 += '}'

            axios
              .post('', {
                query: query2,
              })
              .then(result => {
                messageError(result, this)

                query2 = 'mutation{'
                query2 += `inicial: createContentsResource(input:{content:"${id}",resource:"${this.contentResourceSelected}",isInitial:true}) {
                   contentResource2{
                  id
                isInitial
                  content
                  resource{
                    id
                    name
                    file{
                      id
                      mediaDuration
                    }
                  }
                }
                }
                `

                this.contentResourcesListForUpdate.forEach((element, i) => {
                  if (this.contentResourceSelected !== element) {
                    query2 += `a${i}: createContentsResource(input:{content:"${id}",resource:"${element}",isInitial:false}){
                    contentResource2{
                      id
                    isInitial
                      content
                      resource {
                        id
                        name
                        file{
                            id
                            mediaDuration
                          }
                      }
                      }
                    }`
                  }
                })
                query2 += '}'
                axios
                  .post('', {
                    query: query2,
                  })
                  .then(r => {
                    messageError(r, this)

                    const duration = r.data.data?.inicial?.contentResource2?.resource?.file
                      .mediaDuration
                    axios
                      .post('', {
                        query: `mutation($id : ID!, $duration: Int){
                          updateContents(id:$id,input:{duration:$duration}){
                            content{
                              id
                              name
                              duration
                            }
                          }
                        }`,
                        variables: {
                          id,
                          duration: duration || 0,
                        },
                      })
                      .then(r2 => {
                        messageError(r2, this)

                        this.deleteResource()
                      })
                      .catch(() => { })
                  })
                  .catch(err => {
                    console.log(err)
                  })
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    bytesFormat(data) {
      const kb = (data / 1024).toFixed(2)
      const mb = (data / 1024 ** 2).toFixed(2)
      const gb = (data / 1024 ** 3).toFixed(2)

      if (Math.trunc(gb) === 0) {
        if (Math.trunc(mb) === 0) {
          return `${kb} KB`
        }
        return `${mb} MB`
      }
      return `${gb} GB`
    },
    loadSize() {
      this.searchFiles.forEach(element => {
        const url = element.node.fileUrl
        // eslint-disable-next-line no-undef
        const client = new HttpClient()
        // eslint-disable-next-line no-unused-vars
        client.get(url, response => {
        })
      })
    },
    totalCount() {
      axios
        .post('', {
          query: `
             {
            AUD: allFiles (type:AUD,client:"${this.userData.profile.client.id}" 
            ${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}){
              totalCount
              edgeCount
              edges {
                node {
                  id
                }
              }
            }
              VID: allFiles (type:VID,client:"${this.userData.profile.client.id}" 
              ${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}){
              totalCount
              edgeCount
              edges {
                node {
                  id
                }
              }
              }
              IMG: allFiles (type:IMG,client:"${this.userData.profile.client.id}" 
              ${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}){
              totalCount
              edgeCount
              edges {
                node {
                  id
                }
              }
            }
              SUB: allFiles (type:SUB,client:"${this.userData.profile.client.id}" 
              ${this.sponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}){
              totalCount
              edgeCount
              edges {
                node {
                  id
                }
              }
            }
            }
  
          `,
        })
        .then(result => {
          messageError(result, this)

          this.aud = result.data.data.AUD.totalCount
          this.vid = result.data.data.VID.totalCount
          this.img = result.data.data.IMG.totalCount
          this.count_subtitles = result.data.data.SUB.totalCount
        })
        .catch(() => { })
    },
    onRowSelected(item) {
      this.fileToUser = item.length ? item.map(element => element.node.id) : null
    },
    selectFile() {
      this.isNewFile = true
      document.getElementById('fileInput').click()
    },
    quitarBackground(id) {
      try {
        const icon = document.getElementById(id)

        const elemento = icon.parentNode
        elemento.removeChild(icon)
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    frameVideo(id) {
      const video = document.getElementById(id)
      video.currentTime = video.duration / 2
    },
    fetchData() {
      axios
        .post('', {
          query: `
              query {              
                allFiles(
                first: ${this.perPage},
                offset: ${this.perPage * (this.currentPage - 1)},
                name: "${this.search}",             
                client:"${this.clientId}"
                ${this.sponsor ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
                ) {
                totalCount
                  edges {
                    node {
                      id
                      name
                      type
                      staticUrl
                      createdAt
                      mediaDuration
                      fileUrl
                      
                    }
                  }
                }
              }
  
          `,
        })
        .then(response => {
          messageError(response, this)

          this.files = response.data.data.allFiles
            ? response.data.data.allFiles.edges
            : []

          this.rows = response.data.data.allFiles.totalCount

          this.searchFiles = this.files
        })
        .catch(res => {
          console.log(res)
        })
    },
    fetchMultiPlatformUserData() {
      axios
        .post('', {
          query: `
              query {
                allMultiplatformUsers(id : "${this.userData.id}"){
                  edges{
                    node{
                      id
                      username
                      files{
                        totalCount
                          edges {
                            node {
                              id
                              name
                              type
                              staticUrl
                              createdAt
                              mediaDuration
                              fileUrl
                              
                            }
                          }
                      }
                    }
                  }
                }              
              }
  
          `,
        })
        .then(response => {
          messageError(response, this)
          this.files = response.data.data.allMultiplatformUsers
            ? response.data.data.allMultiplatformUsers.edges[0].node.files.edges
            : []

          this.rows = response.data.data.allMultiplatformUsers.edges[0].node.files.totalCount

          this.searchFiles = this.files
        })
        .catch(res => {
          console.log(res)
        })
    },
    uploadFiles() {
      this.$emit('fileToUpload', this.fileToUser)
    },
    deleteFile(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
                  mutation {
                    deleteFile(id: "${id}") {
                      found
                      deletedId
                    }
                  }
                `,
            })
            .then(r => {
              messageError(r, this)

              showToast(this.$t('success'), 1, this)
              this.loadData()
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    deleteResourcePrev(id, event) {
      console.log(event)
      // event.stopPropagation();
      this.idfile = id
      this.deleteResource()
    },
    deleteResource() {
      axios
        .post('', {
          query: `{
                allFiles(id:"${this.idfile}") {
                     edges {
                    node {
                      id
                      name
                      fileResource {
                        edges {
                          node {
                            id
                            name
                          
                          }
                        }
                      }
                    }
                  }
                }
                }
                 `,
        })
        .then(result => {
          messageError(result, this)

          if (
            result.data.data.allFiles.edges[0].node.fileResource.edges.length
            === 0
          ) {
            this.deleteFile(this.idfile)
          } else {
            this.contentResourceSelected = result.data.data.allFiles.edges[0].node.fileResource.edges[0].node.id

            axios
              .post('', {
                query: `
             {             
              allResources(id:"${this.contentResourceSelected}") {
                edges {
                  node {
                    id
                    name
                    resourceInteractivity {
                      totalCount
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    resource2Interactivity {
                      totalCount
                      edges {
                        node {
                          id
                          name
                           resourceStart {
                            id
                            name
                            type
                            
                          }
                          resourceEnd {
                            id
                            name
                            type
                            
                          }
                        }
                      }
                    }
                    resourceContent {
                      totalCount
                      edges {
                        node {                            
                          id
                          name
                          contentResources(resource:"${utf8ToB64(
    `id:${this.contentResourceSelected}`,
  )}",isInitial:true) {
                          totalCount
                          edges {
                            node {
                              id
                              isInitial
                              content
                               resource {
                                id
                              }
                            }
                          }
                        } 
                        }
                      }
                    }
                  }
                }
              }              
            }
              `,
              })
              .then(res => {
                messageError(res, this)

                const respuesta = res.data.data.allResources.edges[0].node
                this.interactivitieResource = respuesta.resource2Interactivity
                this.contentResource = respuesta.resourceContent
                this.initialRes = false
                this.contentResource.edges.forEach(element => {
                  if (element.node.contentResources.totalCount !== 0) { this.initialRes = true }
                })

                if (
                  !this.initialRes
                  && this.interactivitieResource.totalCount === 0
                ) {
                  this.$refs.modal.hide()
                  this.deleteFile(this.idfile)
                } else {
                  this.$refs.modal.show()
                }
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    addFiles(event) {
      event.preventDefault()
      this.subiendo = false
      event.dataTransfer.files.forEach(element => {
        this.processFile(element)
      })
    },
    showModal(file, event = null) {
      if (event) {
        event.stopPropagation()
        this.boolFile = false
      }
      this.datosObject = file
      if (file.type === 'DOC') {
        window.open(file.fileUrl, '_blank')
      } else {
        this.$refs['view-file-modal'].show()
      }
    },
    hideModal() {
      this.$refs['view-file-modal'].hide()
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl() {
      return noCover
    },
    formatDate(value) {
      const subscriptionDate = new Date(value)
      const newDate = subscriptionDate.toLocaleDateString('en-GB')
      return newDate
    },
    formatTime(duration) {
      return new Date(duration * 1000).toISOString().slice(11, 19)
    },
    emptyFiles(data) {
      this.uploadFilesList = data
      const files = []

      try {
        this.uploadFilesList.forEach(element => {
          this.valuesData.push(element)
          files.push(element.file)
        })
        this.isNewFile = false
        this.fileInput = files
        this.uploadFilesList = []
        this.dataFileInput = []
      } catch (error) {
        console.log(error)
      }
    },
    async getHeader(data) {
      const url = data.fileUrl

      const response = await fetch(url)
      const contentLength = response.headers.get('Content-Length')
      return contentLength
    },
    processFile(file, isNewFile = true, existingValues = null) {
      const newKey = Math.random()
        .toString(36)
        .substring(7)

      let fileToUpload = file

      if (!file.type) {
        // If file type is not set, handle it based on extension
        fileToUpload = handleRawExtensionType(isNewFile ? file : existingValues.file)
      }

      this.uploadFilesList.push({
        key: isNewFile ? newKey : existingValues?.key,
        file: fileToUpload,
        newName: isNewFile ? file.name : existingValues?.newName,
        duration: 0,
        policy: {},
        progress: null,
        thumbnail: isNewFile ? '' : existingValues?.thumbnail,
      })

      this.dataFileInput.push({
        key: isNewFile ? newKey : existingValues?.key,
        file: fileToUpload,
        newName: isNewFile ? file.name : existingValues?.newName,
        duration: 0,
        policy: {},
        progress: null,
        thumbnail: isNewFile ? '' : existingValues?.thumbnail,
      })

      // If there's a reference to mediaUpload, call its setDurImg function
      if (this.$refs.mediaUpload && typeof this.$refs.mediaUpload.setDurImg === 'function') {
        this.$refs.mediaUpload.setDurImg()
      }
    },
    handleFileChange(event) {
      function getType(key, fromUploadedFile = false) {
        switch (key) {
          case 'VID':
            return !fromUploadedFile ? 'Video' : 'video'

          case 'AUD':
            return !fromUploadedFile ? 'Audio' : 'audio'

          case 'IMG':
            return !fromUploadedFile ? 'Imagen' : 'image'

          case 'SUB':
            return !fromUploadedFile ? 'Subtítulo' : 'subtitle'

          case 'DOC':
            return !fromUploadedFile ? 'Documento' : 'document'
          default:
            return null
        }
      }
      const fileInput = event.target
      const file = fileInput.files[0]
      if (file) {
        const key = Math.random()
          .toString(36)
          .substring(7)
        this.uploadData = {
          key,
          file,
          duration: 0,
          policy: {},
          progress: null,
        }
        URL.revokeObjectURL(file)
        let datos = { fileUrl: URL.createObjectURL(file) }
        datos = { ...datos, name: file.name }
        const type = ['video', 'audio', 'image'].find(el => file.type.includes(el))
          || (this.documentTypes.includes(file.type) ? 'document' : null)
          || (this.subtitleTypes.includes(file.type) ? 'subtitle' : null)

        if (getType(this.typeFile, true) === type) {
          this.showModal(datos)
          this.boolFile = true
          fileInput.value = ''
        } else {
          this.$swal({
            title: 'ERROR',
            icon: 'error',
            text: getType(type)
              ? this.$t('notOverwrite', { typeFile: getType(this.typeFile), type: getType(type) })
              : this.$t('reg', { typeFile: getType(this.typeFile) }),
            showCancelButton: false,
            confirmButtonText: this.$t('code.accept'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {

          }).catch(() => {

          })
        }
      }
    },
    addFileToMultiplatformUser(fileId) {
      axios
        .post('', {
          query: `
              mutation {
                updateMultiPlatformUser(id: "${this.userData.id}", input:{filesAdd: ["${fileId}"]}) {
                  multiPlatformUser{
                    id
                  }
                }
              }
            `,
        })
        .then(r => {
          messageError(r, this)

          showToast(this.$t('success'), 1, this)
          this.loadData()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    deleteFileFromMultiplatformUser(fileId) {
      axios
        .post('', {
          query: `
              mutation {
                updateMultiPlatformUser(id: "${this.userData.id}", filesRemove: ["${fileId}"]) {
                  multiPlatformUser{
                    id
                  }
                }
              }
            `,
        })
        .then(r => {
          messageError(r, this)

          showToast(this.$t('success'), 1, this)
          this.loadData()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    getMediaPlatform() {
      console.log('TODO')
    },
  },
}
</script>

<style scoped>
#media .dropContainer {
  border: 3px dashed;
  height: 100%;
}

#media .card-width {
  width: 200px;
}

#media .FileWidth {
  width: 150px;
}

#media .header {
  position: static;
  width: 100%;
  text-align: center;
  /* align-items: center; */
  align-items: baseline;
}

#media .svg {
  margin: auto;
  z-index: 100;
}

#media .color {
  background-color: #000000 !important;
}

#media .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#media .actionsFooter {
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  left: 0;
}

#media .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#media .card-footer .actionsFooter {
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  left: 0;
  bottom: 0;
}

#media .actionFooter {
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: -webkit-center;
}

#media .card-footer {
  border-top: 0;
}

#media .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#media .card-body h4 {
  font-size: 1.286rem !important;
}

#media .card .card-title {
  margin-top: 6rem !important;
  font-weight: bold;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
}

.head {
  display: flex;
  flex-direction: column;
}
</style>
