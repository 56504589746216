<template>
  <section id="selector_resources">
    <b-row class="d-block mb-2">
      <h1>{{ $t("Resources") }}</h1>
      <h5 class="text-primary">
        {{ $t("common.selRec") }}
      </h5>
    </b-row>
    <b-row class="align-items-center">
      <b-col md="4">
        <b-form-input
          v-model="buscar"
          :placeholder="$t('slider.searchThreeCaracResource')"
          minlength="3"
        />
      </b-col>

      <b-col
        v-if="!isEmpty(contenido)"
        md="3"
        class="d-flex justify-content-center"
      >
        <b-form-checkbox
          v-model="resContents"
          switch
          @change="searchByName(null, true)"
        >
          {{ $t("resources.resourceContent") }}
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="1"
        class="d-flex justify-content-start mt-1"
      >
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="fetchData"
        />
      </b-col>
      <b-col md="2">
        <b-form-select
          v-if="msg == null"
          v-model="type"
          :options="resourceType"
          @change="searchByName(null, true)"
        />
      </b-col>
      <b-col class="mb-4 d-flex justify-content-end mr-2">
        <b-button
          :disabled="disabled"
          variant="success"
          class="mt-4"
          @click="seleccionar"
        >
          {{
            $t("dataGeneric.save")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between ">
      <b-col
        v-if="buscar.length <= 2 || showList === true"
        cols="6"
        class="text-center"
      />
      <b-col
        v-else-if="resources.length == 0 && buscar.length != 0 && showList != true"
        class="text-center"
      >
        <h5>{{ $t('subscriptions.notFound') }}</h5>
      </b-col>
      <b-col
        v-else-if="resources.length > 0 && buscar.length != 0"
        cols="6"
        class="mb-2 text-left"
      >
        <h5> {{ $t("common.selRecList") }}</h5>
      </b-col>
      <b-col class="">
        {{ $t("common.prev") }}
        <h3 class="overflow-ellipsis">
          {{ name }}
        </h3>
      </b-col>
    </b-row>
    <b-row style="height:462px">
      <b-col
        style="height:441px"
        class="scroll-list list-group list-group-flush
                                 ml-1 "
      >
        <b-overlay
          variant="transparent"
          :show="showList"
          tag="ul"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-list-group-item
            v-for="res in resources"
            :key="res.node.id"
            :active="id == res.node.id"
            class="cursor-pointer d-flex justify-content-between"
            tag="li"
            @click="clickRow(res)"
            @dblclick="seleccionar"
          >
            <b-card-text class="mb-0 font-weight-bold ">
              {{ res.node.name }}
            </b-card-text>
            <p
              v-if="resContents && recurso !== res.node.id"
              @click.prevent="unlink(res.node.id)"
            >
              <feather-icon
                icon="XCircleIcon"
                size="18"
                class="text-danger cursor-pointer"
              />
            </p>
          </b-list-group-item>
        </b-overlay>
      </b-col>
      <b-col class="d-flex justify-content-center mt-2">
        <b-overlay
          variant="transparent"
          style="min-width: -webkit-fill-available"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="show"
        >
          <div
            v-if="!isEmpty(vidTypeDB)"
            style="text-align: -webkit-center"
          >
            <img
              v-if="vidTypeDB == 'IMG'"
              :src="isEmpty(file) ? imageTest : file
              "
              width="450"
              height="150"
              alt=""
            >
            <div v-else-if="type == 'VID'">
              <div v-if="file.toLowerCase().includes('.jpg') || file.toLowerCase().includes('.png')">
                <b-col class="embed-responsive embed-responsive-16by9">
                  <b-img
                    class="embed-responsive-item"
                    :src="file"
                    style="object-fit: contain;"
                    loading="lazy"
                    alt="image"
                  />
                </b-col>
              </div>
              <video
                v-else
                id="demoRes"
                width="500"
                height="305"
                controls
                class="video-js vjs-default-skin vjs-big-play-centered"
                data-setup="{&quot;liveui&quot;: true}"
                :poster="isEmpty(file) ? '' : (file.toLowerCase().includes('.mp3') ? require('@/assets/images/icons/audio.svg')
                  : '')

                "
                @loadeddata="videoMaker()"
                @loadstart="videoMaker()"
              >
                <source
                  :src="file"
                  :type="file.toLowerCase().includes('.mp4') ? 'video/mp4' :
                    file.toLowerCase().includes('.mp3') ? 'audio/mp3' : 'application/x-mpegURL'
                  "
                >

              </video>
            </div>
            <div v-else>
              <template v-if="type === 'PLN'">
                <div style="height: 500px;">
                  <div
                    class="w-100 h-100"
                    :style="`background-image: linear-gradient(to left, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url(${plain.imageUrl}); background-size: cover;`
                    "
                  >
                    <b-row class="h-100">
                      <b-col cols="4" />
                      <b-col
                        cols="8"
                        class="m-auto"
                      >
                        <h4>{{ plain.title }}</h4>
                        <p class="text-justify pr-2">
                          {{ plain.text }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </template>
              <template v-else-if="type === 'TST'">
                <div style="height: 500px;">
                  <div
                    class="w-100 h-100"
                    :style="`background-image: linear-gradient(to left, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url(${test.backgroundUrl}); background-size: cover;`
                    "
                  >
                    <b-row class="w-100 h-100">
                      <b-col>
                        <div class="mt-4 ml-4">
                          <h5 class="text-white">
                            {{ test.intro }}
                          </h5>
                        </div>
                        <div class="mt-2 ml-4">
                          <h5 class="text-white font-weight-bold">
                            {{ test.name }}
                          </h5>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                          <b-img
                            height="200"
                            width="200"
                            :src="test.imageUrl"
                            fluid
                            alt="Responsive image"
                          />
                        </div>
                      </b-col>
                      <b-col id="respuestas">
                        <div class="pt-5 mt-5">
                          <h5
                            v-for="item in test.testAnswer"
                            :key="item.response"
                            class="font-weight-bold mb-1"
                          >
                            {{ item.response }}
                          </h5>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </template>
              <template v-if="type === 'DEE'">
                <!-- DeepLink -->
              </template>
              <template v-else-if="type === 'A2C'">
                <!-- A2C -->
              </template>
              <template v-else-if="type === 'A2B'">
                <!-- A2B -->
              </template>
            </div>
          </div>
          <div v-else>
            <p>{{ $t("common.recVis") }}</p>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center offset-md-6">
        <small class="word pl-1">
          {{ file }}
        </small>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  isEmpty, showToast, messageError, utf8ToB64,
} from '@/store/functions'

import {
  BCardText,
  BFormSelect,
  BFormCheckbox,
  BCol,
  BPagination,
  BRow,
  BButton,
  BImg,
  BOverlay,
  BFormInput,
  BListGroupItem,

} from 'bootstrap-vue'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BFormSelect,
    BFormCheckbox,
    BImg,
    BPagination,
    BOverlay,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BListGroupItem,
    BCardText,
  },
  props: {
    sponsor: {
      type: Boolean,
      default: false,
    },
    msg: String,
    image: {
      type: Boolean,
      default: false,
    },
    audio: {
      type: Boolean,
      default: false,
    },
    contenido: {
      type: String,
      default: '',
    },
    recurso: {
      type: String,
      default: '',
    },
    initial: {
      type: Boolean,
      default: false,
    },
    special: {
      type: String,
      default: null,
    },
    videotype: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      showList: false,
      disabled: true,
      videojs: null,
      video: null,
      id: null,
      file: null,
      fileEdge: null,
      name: null,
      description: null,
      selected: null,
      emptySearch: this.$t('ResSearch'),
      resources: [],
      folders: null,
      files: null,
      currentParentFolder: null,
      inputFolderName: '',
      inputFolderDescription: '',
      uploadFilesList: [],
      dataFile: '',
      buscar: '',
      resultados: null,
      vidTypeDB: null,
      plain: null,
      directUrl: null,
      type: 'VID',
      test: null,
      headers: {},
      userData: getUserData(),
      imageTest: null,
      resContents: false,
      perPage: 20,
      currentPage: 1,
      rows: null,
      isEmpty,
      resourceType: [
        { text: this.$t('resources.notFilter'), value: '' },
        { text: this.$t('Files'), value: 'VID' },
        { text: this.$t('resources.Survey'), value: 'TST' },
        { text: this.$t('resources.img'), value: 'PLN' },
        { text: this.$t('purchases.title'), value: 'A2B' },
        { text: this.$t('resources.add2Cart'), value: 'A2C' },
        { text: this.$t('Purchases') + this.$t('dataGeneric.internal'), value: 'BUY' },
        { text: this.$t('resources.add2Cart') + this.$t('dataGeneric.internal'), value: 'CAR' },
        { text: 'DeepLink', value: 'DEE' },
      ],
    }
  },
  watch: {
    buscar(oldValue, newValue) {
      if (newValue !== oldValue) {
        if (this.buscar.length >= 3) {
          this.currentPage = 1
          this.fetchData()
        }
        if (this.buscar.length < 3) {
          this.resources = []
        }
        if (this.buscar.length === 0) {
          this.fetchData()
        }
      }
    },
    resContents() {
      if (this.resContents === true) {
        this.currentPage = 1
        this.fetchData()
      }
    },
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      console.log(error)
    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.tipo()
    this.fetchData()
  },
  beforeDestroy() {
    if (this.videojs) this.videojs.dispose()
  },
  methods: {
    unlink(resourceId) {
      const { id } = this.$route.params
      this.$swal({
        title: this.$t('unlinkRes'),
        text: this.$t('sureUnlink'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        const contentID = utf8ToB64(`content:${id}`)
        const query = `{
          contentsResource(content:"${contentID}",resource:"${utf8ToB64(
  `id:${resourceId}`,
)}"){
                  edges {
                      node {
                        id
                        isInitial
                        content
                        resource {
                          id
                          name
                        }
                      }
                    }
                  }
                }`
        axios
          .post('', { query }).then(result => {
            messageError(result, this)
            const resId = result.data.data.contentsResource.edges[0].node.id
            const q = `mutation{
              deleteContentsResource(id:"${resId}"){
                found
              }
            }`
            axios
              .post('', { query: q }).then(res => {
                messageError(res, this).then(r => {
                  if (!r) showToast(this.$t('success'), 1, this)
                }).catch(() => {

                })
                this.fetchData()
              }).catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }).catch(() => {

          })
      }).catch(() => {

      })
    },
    searchByName(event = null, pass = false) {
      let keycode = 0
      if (event) keycode = event.keyCode || event.which

      if (keycode === 13 || pass) {
        if (this.buscar.trim().length >= 3) {
          this.fetchData()
        } else if (this.resContents) {
          this.fetchData()
          this.emptySearch = this.$t('editContent.media')
        } else {
          this.fetchData()
          this.resources = []
          this.rows = null
          this.emptySearch = this.$t('ResSearch')
        }
      }
    },
    tipo() {
      if (this.msg != null) this.type = this.msg
    },
    fetchData() {
      const { headers } = this
      this.showList = true
      const userData = getUserData()

      // Verificamos si `this.type` contiene '|'
      const types = this.type.includes('|') ? this.type.split('|') : [this.type]
      // Función para realizar la petición
      const fetchResources = type => axios.post('', {
        query: `
        query {
          allResources(
          name:"${this.buscar}",
            first:${this.perPage},
            offset:${this.perPage * (this.currentPage - 1)},
            type:${type},
            ${this.videotype ? `videotype:${this.videotype}` : ''},
            client:"${userData.profile.client.id}",
            isImage:${this.image},
            audio:${this.audio},
            ${this.sponsor ? `creator:"${utf8ToB64(`id:${userData.id}`)}",` : ''}
            ${this.special != null ? `,include:"${this.special}"` : ' '}
            ${this.resContents ? `,cont:"${this.contenido}"` : ' '}
           ){
            totalCount
            edges {
              node {
                id
                name
                type
                videotype
                file {
                  id
                  media
                }
              }
            }
          }
        }
      `,
      }, { headers })

      // Verificamos si tenemos un solo tipo o dos para hacer las peticiones
      const promises = types.map(type => fetchResources(type))

      // Ejecutamos todas las peticiones
      Promise.all(promises)
        .then(responses => {
          let allResources = []

          // Combinamos los resultados de todas las peticiones
          responses.forEach(response => {
            messageError(response, this)
            allResources = allResources.concat(response.data.data.allResources.edges)
          })

          // Filtramos y procesamos los recursos
          if (this.initial) {
            allResources = allResources.filter(e => (e.node.file !== null
              ? !e.node.file.media.toLowerCase().includes('jpg')
                && !e.node.file.media.toLowerCase().includes('png')
              : e))
          }

          this.resources = allResources
          this.rows = allResources.length ? allResources[0].node.totalCount : 0

          // Manejo de la selección y otros elementos
          allResources.forEach(element => {
            if (this.recurso === element.node.id) {
              this.clickRow(element)
              this.selected = element.node.id
            }
          })

          this.resultados = this.resources
          this.emptySearch = this.resultados.length === 0 ? this.$t('resultNotFound') : ''
          this.showList = false
        })
        .catch(() => {
          this.showList = false
        })
    },
    clickRow(file) {
      const { headers } = this

      this.show = true
      this.id = file.node.id
      axios
        .post('', {
          query: `
        query
        {
          allResources(id:"${file.node.id}"){
            totalCount
            edges {
              node {
                name
                id
                type
                description
                videotype
                directUrl
                bucketUrl
                file{
                  name
                  fileUrl
                }
                test {
                  id
                  name
                  intro
                  imageUrl
                  backgroundUrl
                  testAnswer{
                    id
                    response
                    order
                  }
                }
                plain {
                  title
                  text
                  imageUrl
                }
              }
            }
          }
        }
        `,
        }, { headers })
        .then(result => {
          messageError(result, this)

          this.disabled = false
          const res = result.data.data.allResources.edges[0]
          this.file = null
          this.vidTypeDB = null
          this.directUrl = null
          this.video = false

          try {
            this.name = res.node.name

            this.description = res.node.description
            this.vidTypeDB = res.node.videotype
            this.directUrl = res.node.directUrl
            this.test = res.node.test
            this.plain = res.node.plain

            try {
              this.file = res.node.file?.fileUrl ?? res.node.directUrl ?? res.node.bucketUrl
              // this.file = isEmpty(res.node.file) ? (isEmpty(res.node.directUrl) ? res.node.bucketUrl : res.node.directUrl) : res.node.file.fileUrl
              this.fileEdge = res.node.file
              this.type = res.node.type
              this.video = true
            } catch (error) {
              this.video = false
            }
            // eslint-disable-next-line no-empty
          } catch (error) { }
          if (this.videojs) {
            if (!isEmpty(this.file)) {
              if (this.file.toLowerCase().includes('.m3u8')) {
                this.videojs.src({
                  type: 'application/x-mpegURL',
                  src: this.file,
                })
              } else if (this.file.toLowerCase().includes('.mp3')) {
                this.videojs.src({
                  type: 'audio/mp3',
                  src: this.file,
                })
              } else {
                this.videojs.src({
                  type: 'video/mp4',
                  src: this.file,
                })
              }
            }
          }
          this.show = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    seleccionar() {
      if (!this.disabled) {
        let data
        if (this.fileEdge) {
          data = [this.id, this.name, this.file]
        } else {
          data = [this.id, this.name]
        }

        this.$emit('data', data)
      }
    },
    busqueda(data) {
      // console.log(data);
      this.buscar = data
      this.fetchData()
    },
    limpiarNombre(nombre) {
      let name = nombre
      try {
        name = name.split(' ')

        name.shift()

        name = name.join(' ')
        // eslint-disable-next-line no-empty
      } catch (error) { }

      return name
    },
    videoMaker() {
      this.videojs = videojs('demoRes')

      this.videojs.on('ready', function () {
        try {
          this.videojs.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#selector_resources .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 350px;
}

#selector_resources .folder {
  min-height: 150px;
}

#selector_resources .word {
  word-break: break-word;
}

#selector_resources .dropContainer {
  border: 3px dashed;
}

#selector_resources .card-width {
  width: 200px;
}

#selector_resources .cursorpointer {
  cursor: pointer;
}

/* .dark-layout select.form-control option:checked, [dir] .dark-layout .custom-select  */

#selector_resources option:checked {
  /* background-color: -internal-light-dark(rgb(206, 206, 206), rgb(84, 84, 84)) !important; */
  background-color: #ffffff !important;
}
</style>
