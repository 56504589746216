<template>
  <b-row id="editSubtitles">
    <!-- VIDEO PLAYER -->
    <b-col class="embed-responsive embed-responsive-16by9">
      <video
        id="EditorPlayer"
        ref="EditorPlayer"
        class="
              video-js
              vjs-default-skin vjs-big-play-centered
              embed-responsive-item"
        data-setup="{&quot;liveui&quot;: true}"
        controls
        :poster="archivoUrlPreview.includes('mp3')
          ? require('@/assets/images/icons/audio.svg') : ''"
        @loadeddata="videoMaker()"
        @loadstart="videoMaker()"
      >
        <source
          :src="archivoUrlPreview"
          :type="archivoUrlPreview.includes('m3u8')
            ? 'application/x-mpegURL'
            : archivoUrlPreview.includes('mp4')
              ? 'video/mp4'
              : 'audio/mpeg'"
        >
      </video>
    </b-col>

    <!-- SUBTITLES -->
    <b-col>
      <b-row :style="viewEdit ? 'display:none' : ''">
        <b-col
          v-if="showSubtitlesSection"
          id="subtitleEditor"
          ref="subtitleEditor"
          style="overflow-y: scroll"
        >
          <div
            v-for="(subtitle, index) in paginatedData"
            :key="index"
          >
            <form>
              <b-row>
                <!-- ID | ADD | REMOVE -->
                <b-col cols="1 pr-0">
                  <div class="form-group d-block">
                    <input
                      :id="'subtitle' + subtitle.id"
                      v-model="subtitle.id"
                      type="text"
                      class="form-control form-group text-center p-0"
                      disabled
                    >
                    <b-button
                      style="padding: 0.5rem 1rem 0.5rem 1rem; margin-bottom: 0.5rem"
                      class="w-100"
                      variant="primary"
                      @click="addNewSubtitle(subtitle.id, index)"
                    >
                      <feather-icon
                        icon="PlusCircleIcon"
                        size="15"
                      />
                    </b-button>
                    <b-button
                      style="padding: 0.5rem 1rem 0.5rem 1rem"
                      class="w-100"
                      variant="primary"
                      @click="removeSubtitle(subtitle.id, index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        size="15"
                      />
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="11">
                  <!-- SUBTITLE TIME OPTIONS -->
                  <b-row>
                    <!-- START TIME -->
                    <b-col cols="6">
                      <div class="form-group">
                        <div class="input-group">
                          <input
                            :id="'startTime' + subtitle.id"
                            v-model="subtitle.startTime"
                            type="text"
                            class="form-control"
                            disabled
                          >
                          <div class="input-group-append">
                            <button
                              class="btn btn-primary form-control"
                              style="padding: 0.5rem 1rem 0.5rem 1rem"
                              type="button"
                              @click="adjustTime(subtitle.startTime, 'startTime', index, 1000)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="15"
                              />
                            </button>
                            <button
                              class="btn btn-primary form-control"
                              style="padding: 0.5rem 1rem 0.5rem 1rem"
                              type="button"
                              @click="adjustTime(subtitle.startTime, 'startTime', index, -1000)"
                            >
                              <feather-icon
                                icon="MinusIcon"
                                size="15"
                              />
                            </button>
                            <button
                              class="btn btn-primary form-control"
                              style="padding: 0.5rem 1rem 0.5rem 1rem"
                              type="button"
                              @click="setCurrentTimeVideoJs(subtitle.startTime)"
                            >
                              <feather-icon
                                icon="EyeIcon"
                                size="15"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- END TIME -->
                    <b-col cols="6">
                      <div class="form-group">
                        <div class="input-group">
                          <input
                            :id="'endTime' + subtitle.id"
                            v-model="subtitle.endTime"
                            type="text"
                            class="form-control"
                            disabled
                          >
                          <div class="input-group-append">
                            <button
                              class="btn btn-primary form-control"
                              style="padding: 0.5rem 1rem 0.5rem 1rem"
                              type="button"
                              @click="adjustTime(subtitle.endTime, 'endTime', index, 1000)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="15"
                              />
                            </button>
                            <button
                              class="btn btn-primary form-control"
                              style="padding: 0.5rem 1rem 0.5rem 1rem"
                              type="button"
                              @click="adjustTime(subtitle.endTime, 'endTime', index, -1000)"
                            >
                              <feather-icon
                                icon="MinusIcon"
                                size="15"
                              />
                            </button>
                            <button
                              class="btn btn-primary form-control"
                              style="padding: 0.5rem 1rem 0.5rem 1rem"
                              type="button"
                              @click="setCurrentTimeVideoJs(subtitle.endTime)"
                            >
                              <feather-icon
                                icon="EyeIcon"
                                size="15"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- SUBTITLE CONTENT -->
                  <b-row>
                    <b-col cols="12">
                      <div class="form-group">
                        <textarea
                          id="content"
                          v-model="subtitle.content"
                          style="resize: none"
                          class="form-control"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </form>
          </div>
        </b-col>
      </b-row>
      <!-- SEARCH BUTTONS -->
      <b-row
        id="searchSubtitle"
        :style="viewEdit ? 'display:none' : ''"
        class="mt-1"
      >
        <!-- SEARCH BY PAGE -->
        <b-col
          cols="4"
          style="padding-right: 0.5rem"
        >
          <div class="form-group">
            <div class="input-group">
              <input
                id="searchSubtitlePage"
                v-model="searchSubtitlePage"
                type="text"
                class="form-control"
                :placeholder="$t('subtitles.searchPage')"
                pattern="^[0-9]+$"
                @keyup.enter="searchPage(searchSubtitlePage)"
              >
              <div class="input-group-append">
                <button
                  class="btn btn-primary form-control"
                  style="padding: 0.5rem 1rem 0.5rem 1rem"
                  type="button"
                  @click="searchPage(searchSubtitlePage)"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="15"
                  />
                </button>
              </div>
            </div>
          </div>
        </b-col>
        <!-- SEARCH BY ID -->
        <b-col
          cols="4"
          style="padding: 0 0.5rem 0 0.5rem"
        >
          <div class="form-group">
            <div class="input-group">
              <input
                id="searchSubtitleId"
                v-model="searchSubtitleId"
                type="text"
                class="form-control"
                :placeholder="$t('subtitles.searchSubtitleId')"
                @keyup.enter="searchId(searchSubtitleId)"
              >
              <div class="input-group-append">
                <button
                  class="btn btn-primary form-control"
                  style="padding: 0.5rem 1rem 0.5rem 1rem"
                  type="button"
                  @click="searchId(searchSubtitleId)"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="15"
                  />
                </button>
              </div>
            </div>
          </div>
        </b-col>
        <!-- SEARCH BY TIME -->
        <b-col
          cols="4"
          style="padding-left: 0.5rem"
        >
          <div class="form-group">
            <div class="input-group">
              <input
                id="searchSubtitleTime"
                v-model="searchSubtitleTime"
                type="time"
                class="form-control"
                maxlength="8"
                step="1"
                @keyup.enter="searchTime(searchSubtitleTime)"
              >
              <div class="input-group-append">
                <button
                  class="btn btn-primary form-control"
                  style="padding: 0.5rem 1rem 0.5rem 1rem"
                  type="button"
                  @click="searchTime(searchSubtitleTime)"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="15"
                  />
                </button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
        :style="!viewEdit ? 'display:none' : ''"
        class="editor-container"
      >
        <b-col ref="TextSubtitlesVtt">
          <b-form-textarea
            v-model="TextSubtitlesVtt"
            class="subtitle-textarea"
          />
        </b-col>
      </b-row>

      <!-- PAGINATION | SAVE | BACK BUTTONS -->
      <b-row
        id="saveChangesButtons"
        class="align-items-center"
      >
        <!-- PAGINATION -->
        <b-col
          cols="4"
          class="d-flex justify-content-start align-items-end"
        >
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :style="viewEdit ? 'display:none' : ''"
            class="mt-1"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            hide-ellipsis
            limit="3"
          />
        </b-col>
        <!-- SAVE | BACK BUTTONS -->
        <b-col
          cols="8"
          class="d-flex justify-content-end align-items-end"
        >
          <b-form-checkbox
            v-model="viewEdit"
            switch
            class="mr-2"
          >
            {{ $t('AlternarView') }}
          </b-form-checkbox>
          <b-button
            id="saveChangesSubtitlesBtn"
            class="mt-1 mr-2"
            variant="success"
            @click="saveChangesSubtitles()"
          >
            {{ $t("subtitles.saveChanges") }}
          </b-button>
          <b-button
            class="btn btn-danger mt-1"
            @click="$emit('hideEditor')"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BPagination,
  BRow,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import videojs from 'video.js'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

export default {
  components: {
    BButton,
    BFormCheckbox,
    BCol,
    BPagination,
    BFormTextarea,
    BRow,
  },
  props: {
    file: {
      type: Object,
      default: () => {},
    },
    originalSubtitles: {
      type: String,
      default: '',
    },
    vttContentArray: {
      type: Array,
      default: () => [],
    },
    archivoUrlPreview: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentPage: 1,
      editorScroll: true,
      perPage: 10,
      rows: null,
      viewEdit: false,
      searchSubtitleId: '',
      searchSubtitlePage: '',
      searchSubtitleTime: '00:00:00',
      showSubtitlesSection: false,
      video: null,
      vttContent: '',
      subtitles: this.vttContentArray,
      TextSubtitlesVtt: this.originalSubtitles,
    }
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.subtitles.slice(start, end)
    },
  },
  watch: {
    currentPage() {
      if (this.editorScroll) {
        const { subtitleEditor } = this.$refs
        if (subtitleEditor) {
          subtitleEditor.scrollTo(0, 0)
        }
      }
      this.editorScroll = true
    },
    viewEdit() {
      if (this.viewEdit) {
        this.$swal({
          title: this.$t('code.atention'),
          text: this.$t('subtValidate.info'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: this.$t('confirm'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(() => {

        }).catch(() => {

        })
      }
    },
  },
  mounted() {
    this.rows = this.subtitles.length
    this.$nextTick(() => {
      this.resizeSubtitleEditor()
    })
    window.addEventListener('resize', this.resizeSubtitleEditor())
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeSubtitleEditor)
    if (this.video) this.video.dispose()
  },
  methods: {
    arrayToVtt(subtitles) {
      let vttContent = 'WEBVTT\n\n'

      for (let i = 0; i < subtitles.length; i += 1) {
        const {
          id, startTime, endTime, content,
        } = subtitles[i]
        vttContent += `${id}\n`
        vttContent += `${startTime} --> ${endTime}\n`
        vttContent += `${content || ' '}\n\n`
      }

      return vttContent
    },
    resizeSubtitleEditor() {
      const modalBody = document.querySelector('.modal-body')
      modalBody.style.height = '90vh'
      const modalBodyStyles = window.getComputedStyle(modalBody)
      let modalBodyHeight = modalBodyStyles.getPropertyValue('height')
      modalBodyHeight = parseFloat(modalBodyHeight)

      this.showSubtitlesSection = true
      this.$nextTick(() => {
        const rowButtons = document.querySelector('#saveChangesButtons')
        const rowButtonsStyles = window.getComputedStyle(rowButtons)
        let rowButtonsHeight = rowButtonsStyles.getPropertyValue('height')
        rowButtonsHeight = parseFloat(rowButtonsHeight)

        const rowSearch = document.querySelector('#searchSubtitle')
        const rowSearchStyles = window.getComputedStyle(rowSearch)
        let rowSearchHeight = rowSearchStyles.getPropertyValue('height')
        rowSearchHeight = parseFloat(rowSearchHeight)

        let subtitleEditorHeight = modalBodyHeight - rowButtonsHeight - rowSearchHeight - 40
        subtitleEditorHeight += 'px'

        const { subtitleEditor, TextSubtitlesVtt } = this.$refs
        if (subtitleEditor) {
          subtitleEditor.style.height = subtitleEditorHeight
        }
        if (TextSubtitlesVtt) {
          TextSubtitlesVtt.style.height = subtitleEditorHeight
        }
      })
    },
    validateVTT() {
      const lines = this.TextSubtitlesVtt.split('\n')

      // 1. Validar que la primera línea es "WEBVTT" y tiene un salto de línea después
      if (lines[0].trim() !== 'WEBVTT' || lines[1].trim() !== '') {
        showToast(this.$t('subtValidate.error1'), 2, this)
        return false
      }

      let index = 1 // Iniciar después de "WEBVTT"
      let lastSequenceNumber = 0 // Variable para almacenar el último numero de secuencia

      while (index < lines.length) {
        let line = lines[index]?.trim() || '' // Evitar valores undefined

        // 🔹 2. Ignorar líneas vacías
        if (line === '') {
          index += 1
          // eslint-disable-next-line no-continue
          continue
        }

        // 🔹 3. Revisar si hay un número de secuencia opcional
        if (!/^\d+$/.test(line)) { // Si no tiene un número de secuencia
          showToast(this.$t('subtValidate.error5', { index }), 2, this)
          return false // Mostrar error si no hay número de secuencia
        }

        const currentSequenceNumber = parseInt(line, 10)
        index += 1
        line = lines[index]?.trim() || ''

        // Comprobación de que los números van en orden
        if (currentSequenceNumber !== lastSequenceNumber + 1) {
          showToast(this.$t('subtValidate.error6', { index }), 2, this)
          return false
        }

        // Actualizar el número de secuencia
        lastSequenceNumber = currentSequenceNumber

        // 🔹 4. Validar formato de tiempo
        const timeRegex = /^\d{2}:\d{2}:\d{2}\.\d{3} --> \d{2}:\d{2}:\d{2}\.\d{3}( .*)?$/
        if (!timeRegex.test(line)) {
          showToast(this.$t('subtValidate.error2', { index: index + 1, line }), 2, this)
          return false
        }

        index += 1 // Avanzar a la siguiente línea

        // 🔹 5. Verificar que haya al menos un subtítulo después del tiempo
        if (index >= lines.length || lines[index]?.trim() === '') {
          showToast(this.$t('subtValidate.error3', { index }), 2, this)
          return false
        }

        // 🔹 6. Recorrer el bloque del subtítulo (incluyendo posibles múltiples líneas de texto)
        let subtitleLineCount = 0

        while (index < lines.length && lines[index]?.trim() !== '') {
          subtitleLineCount += 1 // Contamos las líneas de texto en el bloque de subtítulos
          // 🔹 Verificar si el bloque tiene más de tres línea de texto
          if (subtitleLineCount > 3) {
            showToast(this.$t('subtValidate.error4'), 2, this)
            return false
          }

          index += 1
        }

        index += 1 // Saltar la línea vacía antes del siguiente bloque
      }

      return true
    },

    saveChangesSubtitles() {
      let blob = null
      if (!this.viewEdit) {
        this.vttContent = this.arrayToVtt(this.subtitles)
        blob = new Blob([this.vttContent], { type: 'text/vtt' })
      } else {
        if (!this.validateVTT()) return
        blob = new Blob([this.TextSubtitlesVtt], { type: 'text/vtt' })
      }
      const vttFile = new File([blob], 'archivo.vtt', { type: 'text/vtt' })

      const data = new FormData()

      const query = `
        mutation ($id: ID!, $url: String, $resource: ID, $language: ContentsSubtitleLanguageChoices) {
          updateSubtitle (id: $id, input: {
            resource: $resource,
            language: $language,
            url: $url,
          }) {
            subtitle {
              id
              language
              url
            }
          }
        }
      `
      const variables = {
        id: this.file.id,
        resource: this.$route.params.id,
        language: this.file.language.toUpperCase(),
        url: this.file.url,
      }

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('file_vtt', vttFile)

      axios
        .post('', data)
        .then(response => {
          messageError(response, this)
          this.$emit('hideEditor')
          showToast(this.$t('subtitles.updateSucess'), 1, this)
        })
        .catch(err => {
          messageError(err, this)
          showToast(this.$t('subtitles.updateError'), 2, this)
        })
    },
    videoMaker() {
      this.video = videojs('EditorPlayer')

      this.video.on('ready', () => {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
    // TIME METHODS
    setCurrentTimeVideoJs(time) {
      const milliTime = this.vttTimeToMillis(time)
      const secondTime = milliTime / 1000
      this.video.currentTime(secondTime)
    },
    adjustTime(originalTime, field, id, increment) {
      const absIndex = id + (this.perPage * (this.currentPage - 1))
      const adjustedTime = this.calculateTime(originalTime, increment)

      if (field === 'startTime') {
        if (absIndex === 0) {
          if (adjustedTime >= '00:00:00:000'
            && this.subtitles[absIndex].endTime > adjustedTime) {
            this.subtitles[absIndex].startTime = adjustedTime
          }
        } else if (this.subtitles[absIndex].endTime > adjustedTime
          && this.subtitles[absIndex - 1].endTime <= adjustedTime) {
          this.subtitles[absIndex].startTime = adjustedTime
        }
      } else if (field === 'endTime') {
        if (this.subtitles[absIndex].startTime < adjustedTime
          && this.subtitles[absIndex + 1].startTime >= adjustedTime) {
          this.subtitles[absIndex].endTime = adjustedTime
        }
      }
    },
    calculateTime(originalTime, increment) {
      const time = originalTime.trim()
      let timeMillis = this.vttTimeToMillis(time)
      timeMillis += increment
      return this.millisToVttTime(timeMillis)
    },
    searchTimeToMillis(time) {
      const parts = time.split(':')

      if (parts.length !== 3) {
        return null
      }

      const horas = parseInt(parts[0], 10)
      const minutos = parseInt(parts[1], 10)
      const segundos = parseInt(parts[2], 10)

      if (Number.isNaN(horas) || Number.isNaN(minutos) || Number.isNaN(segundos)) {
        return null
      }

      const milisegundos = horas * 3600000 + minutos * 60000 + segundos * 1000

      return milisegundos
    },
    vttTimeToMillis(time) {
      const timeParts = time.split(/[:.]/)
      const hours = parseInt(timeParts[0], 10)
      const minutes = parseInt(timeParts[1], 10)
      const seconds = parseInt(timeParts[2], 10)
      const milliseconds = parseInt(timeParts[3], 10)

      return hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds
    },
    millisToVttTime(millis) {
      const hours = Math.floor(millis / 3600000)
      let remainingMillis = millis % 3600000
      const minutes = Math.floor(remainingMillis / 60000)
      remainingMillis %= 60000
      const seconds = Math.floor(remainingMillis / 1000)
      const milliseconds = remainingMillis % 1000

      return `${this.padZeroes(hours, 2)}:${this.padZeroes(minutes, 2)}:${this.padZeroes(seconds, 2)}.${this.padZeroes(milliseconds, 3)}`
    },
    padZeroes(num, width) {
      const numString = num.toString()
      return numString.length >= width ? numString : new Array(width - numString.length + 1).join('0') + numString
    },
    // HANDLE SUBTITLES METHODS
    addNewSubtitle(id, index) {
      const startTimeCount = id === 1 ? '00:00:01.000' : this.subtitles[id - 1].endTime
      const endTimeCount = id === this.subtitles.length ? this.calculateTime(startTimeCount, 1000) : this.subtitles[id].startTime
      const subtitleObject = {
        id: id + 1,
        startTime: startTimeCount,
        endTime: endTimeCount,
        content: '',
      }
      for (let i = id; i < this.subtitles.length; i += 1) {
        this.subtitles[i].id += 1
      }
      this.subtitles.splice(id, 0, subtitleObject)
      this.rows += 1
      if (index === 9) {
        this.currentPage += 1
        this.$nextTick(() => {
          this.setActiveNewPage()
        })
      }
    },
    removeSubtitle(id, index) {
      this.subtitles.splice(id - 1, 1)
      for (let i = id - 1; i < this.subtitles.length; i += 1) {
        this.subtitles[i].id -= 1
      }
      if (index === 0) {
        if (this.currentPage !== 1) {
          this.setActiveLastPage()
          this.currentPage -= 1
        }
      }
      this.rows -= 1
    },
    // SEARCH BUTTONS METHODS
    searchId(id) {
      this.currentPage = Math.ceil(id / this.perPage)
      this.editorScroll = false

      this.$nextTick(() => {
        const subtitle = document.querySelector(`#subtitle${id}`)
        const subtitleRect = subtitle.getBoundingClientRect()
        const { subtitleEditor } = this.$refs
        if (subtitleEditor) {
          const editorRect = subtitleEditor.getBoundingClientRect()
          const desplazamiento = subtitleRect.top - editorRect.top + subtitleEditor.scrollTop
          subtitleEditor.scrollTop = desplazamiento
        }
      })
      this.searchSubtitleId = ''
    },
    searchPage(page) {
      this.currentPage = page
      this.searchSubtitlePage = ''
    },
    searchTime(time) {
      const milliseconds = this.searchTimeToMillis(time)
      const newTime = this.millisToVttTime(milliseconds)
      const subtitleCoincidence = this.subtitles.find(subtitle => subtitle.startTime <= newTime && subtitle.endTime >= newTime)
      if (subtitleCoincidence) {
        this.searchId(subtitleCoincidence.id)
        this.setCurrentTimeVideoJs(newTime)
      }
    },
    // PAGINATION METHODS
    setActiveNewPage() {
      const previousPage = document.querySelector('.page-item.active')
      previousPage.classList.remove('active')
      const newCurrentPage = previousPage.nextSibling
      newCurrentPage.classList.add('active')
    },
    setActiveLastPage() {
      const currentPage = document.querySelector('.page-item.active')
      const newCurrentPage = currentPage.previousSibling
      newCurrentPage.classList.add('active')
    },
  },
}
</script>
<style scoped>
div::-webkit-scrollbar {
  display: none
}

textarea::-webkit-scrollbar {
  display: none
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.subtitle-textarea {
  width: 100%;
  /* Ocupa todo el ancho */
  height: 100%;
  /* Se expande hasta llenar el contenedor */
  max-height: calc(100vh - 120px);
  /* Ajusta según tu diseño */
  overflow-y: auto;
  resize: none;
  scrollbar-width: thin;
  /* Hace la barra de scroll más delgada en Firefox */
  scrollbar-color: gray lightgray;
  /* Color de la barra en Firefox */
}

/* Estilo para navegadores basados en WebKit (Chrome, Edge, Safari) */
textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 4px;
}
</style>
