<template>
  <div id="platformSelector">
    <b-overlay
      variant="transparent"
      class="d-flex"
      :show="overlay"
      spinner-variant="primary"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-card
        id="platformSelector"
        style="min-width: -webkit-fill-available"
      >
        <b-row class="mb-2">
          <b-col md="4">
            <h1>{{ $t('Plataformas') }}</h1>
            <h5 class="text-primary">
              {{ $t('selectPlat') }}
            </h5>
          </b-col>
          <b-col
            v-if="manualOrder"
            class="d-flex ml-4"
          >
            <b-button
              variant="outline-warning"
              disabled
            >
              {{ $t("contents.manualSortMode") }}
            </b-button>
          </b-col>
          <b-col>
            <div
              v-if="isSuperuser"
              class="mt-1 float-right"
            >
              <b-col class="d-flex flex-column">
                <b-dropdown
                  v-if="!manualOrder"
                  variant="primary"
                  right
                >
                  <template #button-content>
                    <span class="text-nowrap">
                      <feather-icon
                        icon="ToolIcon"
                        class="mr-25"
                      />
                      {{ $t("resources.tableHeader.actions") }}</span>
                  </template>
                  <b-dropdown-item @click.stop.prevent="activateManualOrder()">
                    <span
                      class="text-nowrap"
                      style="color: white"
                    >
                      <feather-icon
                        icon="FolderPlusIcon"
                        class="mr-50"
                      />
                      {{ $t("contents.sortManual") }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <ReorderPlatformsButton
                      v-if="isSuperuser && !manualOrder"
                      class="mt-25"
                      :disabled="manualOrder"
                      @reordering="overlay = true"
                      @reordered="fetchData()"
                    />
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  v-else
                  variant="success"
                  @click.stop.prevent="activateManualOrder()"
                >
                  <span class="text-nowrap">
                    <feather-icon
                      icon="CheckCircleIcon"
                      class="mr-50"
                    />
                    {{ $t("contents.finalize") }}</span>
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>

        <b-row
          cols="12"
          class="mb-2"
        >
          <b-col>
            <b-form-input
              id="search_platform"
              :placeholder="$t('Search')"
              autofocus
              @change="busqueda"
            />
          </b-col>
        </b-row>
        <b-row>
          <template v-if="manualOrder">
            <div
              ref="infiniteScroll"
              class="manualOrder body-scrollable"
              @scroll="handleScroll"
            >
              <draggable
                v-model="platforms"
                :disabled="buscar != null"
                class="row rounded"
                style="min-width: -webkit-fill-available"
                @start="onStart"
                @end="onEnd"
              >
                <b-col
                  v-for="platform in platforms"
                  :id="platform.node.id + ' ' + platform.node.order"
                  :key="platform.node.id"
                  v-b-tooltip.hover.top="platform.node.name"
                  cols="3"
                  @dragend="updateOrder($event)"
                >
                  <b-card
                    id="img"
                    class="cursor-pointer text-center mb-2 embed-responsive embed-responsive-16by9"
                    style="overflow: visible !important;"
                    no-body
                  >
                    <b-img
                      rounded="lg"
                      :src="buildImageUrl(platform)"
                      style="object-fit: contain;"
                      class="embed-responsive-item"
                    />
                    <b-card-body>
                      <div class="actionsBar right">
                        <div class="action">
                          <b-dropdown
                            class="center"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            right
                          >
                            <template #button-content>
                              <feather-icon
                                color="white"
                                icon="MoreVerticalIcon"
                                size="20"
                              />
                            </template>
                            <b-dropdown-item
                              v-if="platform.node.order < maxOrder"
                              @click.prevent="movePlatformToTop(platform)"
                            >
                              <div class="d-flex">
                                <feather-icon
                                  icon="ArrowUpIcon"
                                  size="20"
                                />
                                {{ $t('platformSelector.moveTop') }}
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="platform.node.order > 1"
                              @click.prevent="movePlatformToBottom(platform)"
                            >
                              <div class="d-flex">
                                <feather-icon
                                  icon="ArrowDownIcon"
                                  size="20"
                                />
                                {{ $t('platformSelector.moveDown') }}
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="platform.node.order > perPage"
                              @click.prevent="movePlatformToBottom(platform, false)"
                            >
                              <div class="d-flex">
                                <feather-icon
                                  icon="FileMinusIcon"
                                  size="20"
                                />
                                {{ $t('platformSelector.movePartialDown') }}
                              </div>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                  {{ platform.node.displayName }}
                </b-col>
              </draggable>
            </div>
          </template>
          <template v-else>
            <div
              ref="infiniteScroll"
              class="body-scrollable"
              @scroll="handleScroll"
            >
              <b-col
                v-for="platform in platforms"
                :id="platform.node.id + ' ' + platform.node.order"
                :key="platform.node.id"
                v-b-tooltip.hover.top="platform.node.name"
                cols="3"
              >
                <b-card
                  id="img"
                  class="cursor-pointer text-center mb-2 embed-responsive embed-responsive-16by9"
                  @click="onRowSelected(platform)"
                >
                  <b-img
                    rounded="lg"
                    :src="buildImageUrl(platform)"
                    style="object-fit: contain;"
                    class="embed-responsive-item"
                  />
                </b-card>
                {{ platform.node.displayName }}
              </b-col>
            </div>
          </template>
        </b-row>
        <div
          v-if="hasMore"
          class="text-center"
        >
          <small>{{ $t('downToSeePlat') }}</small><feather-icon
            icon="ArrowDownIcon"
            size="20"
          />
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard,
  BCol,
  BRow,
  BImg,
  VBTooltip,
  BFormInput,
  BOverlay,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { clientNode } from '@/auth/utils'
import draggable from 'vuedraggable'
import ReorderPlatformsButton from '@/views/common/ReorderPlatformsButton.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BFormInput,
    BImg,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BOverlay,
    BButton,
    BDropdown,
    BDropdownItem,
    draggable,
    ReorderPlatformsButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isSuperuser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videojs: null,
      video: false,
      fileUrl: null,
      name: null,
      selected: null,
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      platforms: [],
      currentParentFolder: null,
      tableColumns: [
        { key: 'logo', label: 'Logo' },
        { key: 'platform', label: 'Plataforma' },
      ],
      scrollPage: 1,
      perPage: 40,
      currentPage: 1,
      rows: null,
      buscar: null,
      manualOrder: false,
      hasMore: true,
      overlay: false,
      maxOrder: 0,
      initialIndex: null,
      finalIndex: null,
    }
  },

  mounted() {
    const scrollContainer = this.$refs.infiniteScroll
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', this.handleScroll)
    }
    this.handleScroll()
  },
  destroyed() {
    const scrollContainer = this.$refs.infiniteScroll
    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll() {
      const element = this.$refs.infiniteScroll

      const containerHeight = element.clientHeight // Height of the scroll container
      const { scrollHeight } = element // Total height of the scrollable content
      const { scrollTop } = element

      if (scrollTop + containerHeight + 1 >= scrollHeight && this.hasMore && !this.overlay) {
        this.fetchData()
        this.overlay = true
      }
    },
    seleccionar() {
      this.$emit('client', this.selected)
    },
    onRowSelected(data) {
      this.selected = data.node
      this.seleccionar()
    },
    buildImageUrl(folder) {
      return folder.node.logo == null || folder.node.logo.length === 0
        ? noCover
        : folder.node.logo
    },

    fechasSplit(value) {
      try {
        const fecha = value
        const nueva = fecha.split(' ')[0]
        const format = nueva.split('-')
        return `${format[2].split('T')[0]}-${format[1]}-${format[0]}`
      } catch (error) {
        return null
      }
    },
    fetchData(reload = false) {
      this.overlay = true
      const { scrollPage } = this

      const element = this.$refs.infiniteScroll
      const containerHeight = element.clientHeight // Height of the scroll container
      const { scrollTop } = element
      const scrollY = containerHeight + scrollTop

      let actualPage = scrollPage
      if (reload) {
        this.platforms = []
        actualPage = 1
      }
      this.getRootOffset(reload, scrollPage, actualPage, scrollY)
    },
    getRootOffset(reload, scrollPage, actualPage, scroll) {
      axios
        .post('', {
          query: `
         query {
                allClients(
                  orderBy:"-order",
                  first:${this.perPage},
                  offset:${this.perPage * (actualPage - 1)},
                  ${this.buscar != null ? `name_Icontains: "${this.buscar}",` : ''}                 
                  
                  ){
                pageInfo{
                  hasPreviousPage
                  hasNextPage
                }
                totalCount
                edgeCount
                edges{                  
                    node{
                      ${clientNode()}
                      name
                      order
                    }
                  }
                  }
                }
        `,
        })
        .then(response => {
          messageError(response, this)

          const clients = response.data.data.allClients.edges
          this.platforms = [...this.platforms, ...clients]

          this.rows += response.data.data.allClients.totalCount
          if (scrollPage === 1) this.maxOrder = this.platforms[0].node.order

          if (!reload) this.scrollPage += 1
          this.hasMore = response.data.data.allClients.pageInfo.hasNextPage

          if (actualPage < (scrollPage - 1)) {
            this.getRootOffset(reload, scrollPage, actualPage + 1, scroll)
          } else {
            if (scroll !== null) {
              this.$refs.infiniteScroll.scrollTo(0, scroll)
            }
            this.overlay = false
          }
        }).catch(() => {
        })
    },

    busqueda(data) {
      this.buscar = data || null
      this.fetchData(true)
    },
    activateManualOrder() {
      this.manualOrder = !this.manualOrder
    },
    async movePlatformToTop(platform) {
      try {
        this.overlay = true

        const { order } = platform.node

        let allPlatforms = []
        if (this.buscar) {
          await this.fetchAllClients(this.maxOrder - order, 0, []).then(result => {
            allPlatforms = [...result]
          })
        } else {
          allPlatforms = this.platforms
        }
        const index = allPlatforms.findIndex(obj => obj.node.order === order)
        if (index > -1) {
          allPlatforms.splice(index)
          allPlatforms.unshift(platform)
        } else {
          throw new Error(this.$t('platformOrderError'))
        }
        const arrayNum = []
        const arrayId = []
        allPlatforms.forEach((item, i) => {
          arrayId.push(item.node.id)
          arrayNum.push(this.maxOrder - i)
        })

        const batchSize = 100 // Max elements per batch
        const batches = []
        for (let i = 0; i < arrayId.length; i += batchSize) {
          batches.push({
            ids: arrayId.slice(i, i + batchSize),
            orders: arrayNum.slice(i, i + batchSize),
          })
        }

        await this.updateClientsBatch(batches, 0).then(() => {
          showToast(this.$t('clientOrderSuccess'), 1, this)
          this.fetchData(true)
        }).catch(error => {
          console.error(error)
          showToast(this.$t('error'), 0, this)
        })
      } catch (error) {
        this.overlay = false
        console.error(error)
        showToast(this.$t('error'), 0, this)
      }
    },
    async movePlatformToBottom(platform, full = true) {
      try {
        this.overlay = true

        const { order } = platform.node

        let allPlatforms = []
        await this.fetchAllClients(this.maxOrder - order, this.maxOrder - order, [], false, full ? 300 : this.perPage, !!full).then(result => {
          allPlatforms = [...result]
        })

        const index = allPlatforms.findIndex(obj => obj.node.order === order)
        if (index > -1) {
          allPlatforms.splice(0, index + 1)
          allPlatforms.push(platform)
        } else {
          throw new Error(this.$t('platformOrderError'))
        }

        const arrayId = allPlatforms.map(item => item.node.id)
        const arrayNum = allPlatforms.map((_, i) => order - i)

        const batchSize = 100 // Max elements per batch
        const batches = []
        for (let i = 0; i < arrayId.length; i += batchSize) {
          batches.push({
            ids: arrayId.slice(i, i + batchSize),
            orders: arrayNum.slice(i, i + batchSize),
          })
        }

        await this.updateClientsBatch(batches, 0).then(() => {
          showToast(this.$t('clientOrderSuccess'), 1, this)
          this.fetchData(true)
        }).catch(error => {
          console.error(error)
          showToast(this.$t('error'), 0, this)
        })
      } catch (error) {
        this.overlay = false
        console.error(error)
        showToast(this.$t('error'), 0, this)
      }
    },
    async updateClientsBatch(batches, currentBatchIndex) {
      if (currentBatchIndex >= batches.length) return

      const batch = batches[currentBatchIndex]
      let query = 'mutation {'
      batch.ids.forEach((id, i) => {
        query += `
      m${i + 1}: updateClient(id: "${id}", input: { order: ${batch.orders[i]} }) {
        client {
          id
          name
          order
        }
      }
    `
      })
      query += '}'

      try {
        await axios
          .post('', {
            query,
          }).then(res => {
            messageError(res, this)
            return this.updateClientsBatch(batches, currentBatchIndex + 1)
          })
      } catch (error) {
        console.error(`Error processing batch ${currentBatchIndex}:`, error)
        showToast(this.$t('error'), 0, this)
      }
    },
    async updateOrder(event) {
      if (this.buscar != null || this.finalIndex === null) return
      this.overlay = true
      const arrayNum = []
      const arrayId = []
      const evento = event.target.parentNode.childNodes
      const [start, end] = this.initialIndex < this.finalIndex ? [this.initialIndex, this.finalIndex] : [this.finalIndex, this.initialIndex]
      for (let i = start; i <= end; i += 1) {
        if (evento[i]) {
          arrayId.push(evento[i].id.split(' ')[0])
          arrayNum.push(evento[i].id.split(' ')[1])
        }
      }
      arrayNum.sort((a, b) => b - a)
      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      const batchSize = 100 // Max elements per batch
      const batches = []
      for (let i = 0; i < arrayId.length; i += batchSize) {
        batches.push({
          ids: arrayId.slice(i, i + batchSize),
          orders: result.slice(i, i + batchSize),
        })
      }

      await this.updateClientsBatch(batches, 0).then(() => {
        showToast(this.$t('clientOrderSuccess'), 1, this)
        this.overlay = false
        this.initialIndex = null
        this.finalIndex = null
        this.fetchData(true)
      }).catch(error => {
        console.error(error)
        showToast(this.$t('error'), 0, this)
        this.overlay = false
      })
    },
    async fetchAllClients(platformIndex = 0, offset = 0, allPlatforms = [], fetchFromStart = true, batchSize = 300, unlimitedFetch = true) {
      let currentIndex = platformIndex
      let currentPlatforms = []
      try {
        const elementsToFetch = fetchFromStart ? Math.min(batchSize, currentIndex + 1) : batchSize
        // Fetch the current batch
        const response = await axios.post('', {
          query: `
            query {
              allClients(
                orderBy: "-order",
                first: ${elementsToFetch},
                offset: ${offset},
              ) {
                pageInfo {
                  hasNextPage
                }
                edges {
                  node {
                    id
                    name
                    order
                  }
                }
              }
            }
          `,
        })

        // Error handling
        messageError(response, this)

        // Extract the current batch of clients
        const data = response.data.data.allClients
        const clients = data.edges

        // Add the current batch to the cumulative list
        currentPlatforms = [...allPlatforms, ...clients]

        // Check if there are more pages
        if (data.pageInfo.hasNextPage && unlimitedFetch) {
          if (fetchFromStart) {
            // Recursive call from start to index
            currentIndex -= batchSize
          } else {
            // Recursive call from index to end
            currentIndex += batchSize
          }
          if (currentIndex > 0) return this.fetchAllClients(currentIndex, offset + batchSize, currentPlatforms, fetchFromStart)
        }
        return currentPlatforms
      } catch (error) {
        console.error('Error fetching clients:', error)
        showToast(this.$t('error'), 0, this)
        return [] // Return an empty array on error
      }
    },
    onStart(event) {
      this.initialIndex = event.oldIndex // Store initial index
    },
    onEnd(event) {
      this.finalIndex = event.newIndex // Get final index
    },
  },

}
</script>

<style lang="scss" scoped>
#platformSelector .manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

#platformSelector .td {
  width: 50px;
  height: 100px;
}

#platformSelector .folder {
  min-height: 150px;
  height: 200;
  cursor: pointer;
}

#platformSelector .dropContainer {
  border: 3px dashed;
}

#platformSelector .card-width {
  width: 200px;
}

#platformSelector .list-group-item {
  transition: all 1s;
}

#platformSelector .body-scrollable {

  overflow-y: auto;
  overflow-x: hidden;
  max-height: 550px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

#platformSelector .overlay-container {
  position: fixed;
  /* Posición fija en la ventana del navegador */
  top: 0;
  /* Coloca el contenedor en la parte superior */
  left: 130px;
  /* Coloca el contenedor en el lado izquierdo */
  width: 100%;
  /* Ancho completo */
  height: 100%;
  /* Altura completa */
  display: flex;
  /* Activa el modelo de caja flexible */
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  z-index: 9999;
  /* Z-index alto para asegurar que esté por encima de otros elementos */
}

#platformSelector .actionsBar {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#platformSelector .card-body .actionsBar {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;

}

#platformSelector .right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
</style>
